import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'
import theme from '../utils/theme'
import DiscountType from '../types/discount'
import Button from './Button'
import { useHistory } from 'react-router-dom'
import {
  DiscountDescription,
  PartnerDescription,
  PartnerImage,
  PartnerName,
} from '../styles'
import dialog from '../utils/dialog'
import UserType, { hasCompletedIntegration } from '../types/user'
import { getUser } from '../utils/api'
import useAuth from '../hooks/auth'
import Linkify from 'linkify-react'
import 'linkify-plugin-mention'

type PropType = {
  discount: DiscountType
}

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  border-style: solid;
  border-color: ${theme.color.primary};
  border-width: 5px;
  padding: 8px;
  background: ${theme.color.initial};
  width: 220px;
  height: 370px;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 430px) {
    width: 150px;
    height: 310px;
  }
`

const CardImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 100px;
  max-height: 100px;
`

const CardTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 2;
`

const CardButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex: 2;
`

const CardButton = styled(Button)`
  background: ${theme.color.primary};
  border-color: ${theme.color.primary};
  color: ${theme.color.initial};
  height: 4.8rem;
  padding: 0 5rem;
  margin-bottom: 5px !important;
  &:hover {
    background: ${theme.color.primary};
    border-color: ${theme.color.primary};
  }
  @media (max-width: 430px) {
    height: 2.8rem;
    padding: 0 1rem;
    margin-bottom: 1px !important;
    line-height: 1.4rem;
  }
`

export default ({ discount }: PropType) => {
  const history = useHistory()
  const [auth] = useAuth()
  const [user, setUser] = useState<UserType>()

  useEffect(() => {
    let mounted = true
    if (auth != null) {
      getUser(auth?.uid).then((user: UserType) => {
        if (mounted) {
          setUser(user)
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [auth, user])

  const onClick = () => {
    if (
      (discount?.partner?.uid == '54' || discount?.partner?.uid == '55') &&
      user !== undefined &&
      !hasCompletedIntegration(user)
    ) {
      dialog?.alert(
        'Infelizmente, seu dados ainda não foram compartilhados com o Parceiro. Por favor, entre em contato conosco. ',
      )
    } else {
      history.push(`/discounts/${discount?.uid}`)
    }
  }

  return (
    <CardWrapper>
      <CardImageWrapper>
        <PartnerImage
          src={discount?.imageUrl || discount?.partner?.imageUrl}
          loading="lazy"
        />
      </CardImageWrapper>
      <CardTextWrapper>
        <PartnerName>{discount?.partner?.name}</PartnerName>
        <PartnerDescription title={discount?.partner?.description}>
          {discount?.partner?.description}
        </PartnerDescription>
      </CardTextWrapper>
      <CardButtonWrapper>
        <DiscountDescription title={discount?.description}>
          <Linkify
            options={{
              formatHref: {
                mention: href => 'https://www.instagram.com/' + href,
              },
              target: '_blank',
              attributes: {
                style: {
                  ...{
                    color: `${theme?.color?.primary}`,
                    fontWeight: `${theme?.font?.bold}`,
                  },
                },
              },
            }}
          >
            <div>{discount?.description}</div>
          </Linkify>
        </DiscountDescription>
        <CardButton onClick={onClick}>Obter Desconto</CardButton>
      </CardButtonWrapper>
    </CardWrapper>
  )
}
