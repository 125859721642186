import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropsType = {
  className?: string
  color?: string | undefined
  onClick?: any
  type: string
}

const Icon = styled.svg`
  fill: ${({ color }) => color || '#888'};
  height: 2rem;
  margin-bottom: -0.4rem;
  transition: opacity 0.3s;
`

export const getIcon = (type: string = '') => {
  switch (type) {
    case 'logo':
      return (
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="166.7856"
            y1="345.841"
            x2="202.6651"
            y2="345.841"
          >
            <stop offset="0" style={{ stopColor: '#C6A35E' }} />
            <stop offset="1" style={{ stopColor: '#916923' }} />
          </linearGradient>
          <circle
            style={{ fill: 'url(#SVGID_1_)' }}
            cx="184.73"
            cy="345.84"
            r="17.94"
          />
          <linearGradient
            id="SVGID_00000080906370746058550300000012540675992560086204_"
            gradientUnits="userSpaceOnUse"
            x1="183.0963"
            y1="267.981"
            x2="218.9758"
            y2="267.981"
          >
            <stop offset="0" style={{ stopColor: '#C6A35E' }} />
            <stop offset="1" style={{ stopColor: '#916923' }} />
          </linearGradient>
          <circle
            style={{
              fill:
                'url(#SVGID_00000080906370746058550300000012540675992560086204_)',
            }}
            cx="201.04"
            cy="267.98"
            r="17.94"
          />
          <g>
            <text
              transform="matrix(1 0 0 1 236.5216 387.4659)"
              style={{
                fill: '#54173E',
                fontFamily: 'Caxo',
                fontSize: '102.7485px',
                letterSpacing: '-2',
              }}
            >
              SEGONHA
            </text>
          </g>
          <linearGradient
            id="SVGID_00000054260819759279243710000007816910380431326876_"
            gradientUnits="userSpaceOnUse"
            x1="159.1366"
            y1="310.8662"
            x2="225.5041"
            y2="310.8662"
          >
            <stop offset="0" style={{ stopColor: '#C6A35E' }} />
            <stop offset="1" style={{ stopColor: '#916923' }} />
          </linearGradient>
          <path
            style={{
              fill:
                'url(#SVGID_00000054260819759279243710000007816910380431326876_)',
            }}
            d="M196.35,222.95
            c-1.12,1.99-2.31,3.9-3.47,5.82l-3.44,5.75c-1.08,1.94-2.28,3.8-3.31,5.74c-0.52,0.97-1.07,1.91-1.61,2.86l-1.51,2.89
            c-1.99,3.83-3.86,7.68-5.27,11.53c-0.69,1.93-1.35,3.83-1.82,5.72c-0.51,1.88-0.77,3.74-1,5.51c-0.12,1.76-0.17,3.47,0.11,5.03
            c0.08,0.8,0.3,1.53,0.45,2.28c0.1,0.37,0.25,0.72,0.36,1.08c0.1,0.36,0.25,0.71,0.42,1.05c0.27,0.7,0.68,1.36,1.04,2.03
            c0.46,0.65,0.82,1.32,1.36,1.96c0.96,1.31,2.16,2.58,3.51,3.82c5.38,5.06,12.94,9.48,20.43,15.07c1.88,1.4,3.77,2.9,5.65,4.5
            c1.86,1.65,3.72,3.34,5.47,5.3c0.89,0.94,1.72,2.01,2.56,3.03c0.79,1.11,1.63,2.15,2.35,3.34c1.51,2.29,2.78,4.86,3.77,7.51
            c1.9,5.36,2.63,11.16,2.01,16.47c-0.28,2.66-0.74,5.25-1.42,7.71c-0.67,2.47-1.53,4.82-2.45,7.1c-0.49,1.12-0.96,2.25-1.48,3.35
            l-1.66,3.2c-0.55,1.07-1.18,2.06-1.77,3.09c-0.58,1.03-1.21,2.03-1.87,2.99c-2.52,3.96-5.39,7.58-8.3,11.13
            c-1.5,1.73-3.02,3.44-4.55,5.12c-1.6,1.63-3.14,3.29-4.78,4.86c-1.64,1.57-3.3,3.12-4.98,4.63c-1.73,1.48-3.44,2.95-5.23,4.36
            c4.21-8.05,8.62-15.78,12.47-23.64c3.82-7.83,7.35-15.71,9.32-23.48c0.48-1.93,0.9-3.85,1.13-5.73c0.28-1.88,0.4-3.71,0.39-5.48
            c-0.03-3.55-0.74-6.73-2.03-9.66c-2.57-5.84-8.16-10.88-14.89-15.88c-3.35-2.51-6.99-5.02-10.68-7.79
            c-3.67-2.84-7.52-5.79-11.07-9.65c-0.9-0.93-1.75-2-2.6-3.02c-0.82-1.1-1.65-2.18-2.39-3.4c-1.52-2.36-2.78-5.02-3.62-7.85
            c-0.23-0.7-0.43-1.4-0.57-2.13c-0.15-0.72-0.32-1.44-0.43-2.16c-0.16-1.45-0.37-2.9-0.32-4.33c-0.05-2.88,0.41-5.62,0.99-8.23
            c0.65-2.58,1.53-5.03,2.56-7.31c1.02-2.3,2.18-4.46,3.45-6.52c2.49-4.14,5.42-7.81,8.44-11.3l2.32-2.57
            c0.8-0.82,1.59-1.65,2.4-2.46c1.59-1.64,3.28-3.17,4.96-4.71c1.68-1.53,3.45-2.97,5.18-4.42
            C192.71,225.66,194.51,224.28,196.35,222.95z"
          />
          <text
            transform="matrix(1 0 0 1 231.7843 299.3163)"
            style={{
              fill: '#54173E',
              fontFamily: 'Caxo',
              fontSize: '102.7485px',
              letterSpacing: '2',
            }}
          >
            CLUBE
          </text>
        </g>
      )

    case 'white-logo':
      return (
        <g>
          <circle style={{ fill: '#fff' }} cx="179.07" cy="357.49" r="17.94" />
          <circle style={{ fill: '#fff' }} cx="195.38" cy="279.63" r="17.94" />
          <g>
            <text
              transform="matrix(1 0 0 1 230.8629 399.1167)"
              style={{
                fill: '#fff',
                fontFamily: 'Caxo',
                fontSize: '102.7485px',
                letterSpacing: '-2',
              }}
            >
              SEGONHA
            </text>
          </g>
          <g>
            <path
              style={{ fill: '#fff' }}
              d="M190.69,234.61c-7.45,13.29-25.93,39.06-20.51,54.21c4.29,10.63,16.81,16.14,26.76,23.92
		c3.92,2.94,7.74,6.07,11.12,9.8c20.48,22.05,8.64,49.06-8.27,68.93c-5.92,6.92-12.42,13.31-19.54,18.97
		c8.74-17.57,29.74-48.99,21.28-67.99c-2.58-5.84-8.16-10.88-14.89-15.88c-8.26-6.2-17.57-11.93-24.35-20.46
		C141.48,280.01,169.55,249.84,190.69,234.61L190.69,234.61z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 226.1255 310.9672)"
            style={{
              fill: '#fff',
              fontFamily: 'Caxo',
              fontSize: '102.7485px',
              letterSpacing: '2',
            }}
          >
            CLUBE
          </text>
        </g>
      )

    case 'logo-back':
      return (
        <g>
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="159.6199"
            y1="354.7444"
            x2="195.4994"
            y2="354.7444"
          >
            <stop offset="0" style={{ stopColor: '#C6A35E' }} />
            <stop offset="1" style={{ stopColor: '#916923' }} />
          </linearGradient>
          <circle
            style={{ fill: 'url(#SVGID_1_)' }}
            cx="177.56"
            cy="354.74"
            r="17.94"
          />
          <linearGradient
            id="SVGID_00000167383248006419029350000013512635151448025229_"
            gradientUnits="userSpaceOnUse"
            x1="175.9306"
            y1="276.8843"
            x2="211.8101"
            y2="276.8843"
          >
            <stop offset="0" style={{ stopColor: '#C6A35E' }} />
            <stop offset="1" style={{ stopColor: '#916923' }} />
          </linearGradient>
          <circle
            style={{
              fill:
                'url(#SVGID_00000167383248006419029350000013512635151448025229_)',
            }}
            cx="193.87"
            cy="276.88"
            r="17.94"
          />
          <g>
            <text
              transform="matrix(1 0 0 1 229.3559 396.3692)"
              style={{
                fill: '#fff',
                fontFamily: 'Caxo',
                fontSize: '102.7485px',
                letterSpacing: '-2',
              }}
            >
              SEGONHA
            </text>
          </g>
          <g>
            <linearGradient
              id="SVGID_00000113333923080629446460000011596360239661618854_"
              gradientUnits="userSpaceOnUse"
              x1="151.9708"
              y1="319.7696"
              x2="218.3384"
              y2="319.7696"
            >
              <stop offset="0" style={{ stopColor: '#C6A35E' }} />
              <stop offset="1" style={{ stopColor: '#916923' }} />
            </linearGradient>
            <path
              style={{
                fill:
                  'url(#SVGID_00000113333923080629446460000011596360239661618854_)',
              }}
              d="M189.19,231.86
		c-1.12,1.99-2.31,3.9-3.47,5.82l-3.44,5.75c-1.08,1.94-2.28,3.8-3.31,5.74c-0.52,0.97-1.07,1.91-1.61,2.86l-1.51,2.89
		c-1.99,3.83-3.86,7.68-5.27,11.53c-0.69,1.93-1.35,3.83-1.82,5.72c-0.51,1.88-0.77,3.74-1,5.51c-0.12,1.76-0.17,3.47,0.11,5.03
		c0.08,0.8,0.29,1.53,0.45,2.28c0.1,0.37,0.25,0.72,0.36,1.08c0.1,0.36,0.25,0.71,0.42,1.05c0.27,0.7,0.68,1.36,1.04,2.03
		c0.46,0.65,0.82,1.32,1.36,1.96c0.96,1.31,2.16,2.58,3.51,3.82c5.38,5.06,12.94,9.48,20.43,15.07c1.88,1.4,3.77,2.9,5.65,4.5
		c1.86,1.65,3.72,3.34,5.47,5.3c0.89,0.94,1.72,2.01,2.56,3.03c0.79,1.11,1.63,2.15,2.35,3.34c1.51,2.29,2.78,4.86,3.77,7.51
		c1.9,5.36,2.63,11.16,2.01,16.47c-0.28,2.66-0.74,5.25-1.42,7.71c-0.67,2.47-1.53,4.82-2.45,7.1c-0.49,1.12-0.96,2.25-1.48,3.35
		l-1.66,3.2c-0.55,1.07-1.18,2.06-1.77,3.09c-0.58,1.03-1.21,2.03-1.87,2.99c-2.52,3.96-5.39,7.58-8.3,11.13
		c-1.5,1.73-3.02,3.44-4.55,5.12c-1.6,1.63-3.14,3.29-4.78,4.86c-1.64,1.57-3.3,3.12-4.98,4.63c-1.73,1.48-3.44,2.95-5.23,4.36
		c4.21-8.05,8.62-15.78,12.47-23.64c3.82-7.83,7.35-15.71,9.32-23.48c0.48-1.93,0.9-3.85,1.13-5.73c0.28-1.88,0.4-3.71,0.39-5.48
		c-0.03-3.55-0.74-6.73-2.03-9.66c-2.57-5.84-8.16-10.88-14.89-15.88c-3.35-2.51-6.99-5.02-10.68-7.79
		c-3.67-2.84-7.52-5.79-11.07-9.65c-0.9-0.93-1.75-2-2.6-3.02c-0.82-1.1-1.65-2.18-2.39-3.4c-1.52-2.36-2.78-5.02-3.62-7.85
		c-0.23-0.7-0.43-1.4-0.57-2.13c-0.15-0.72-0.32-1.44-0.43-2.16c-0.16-1.45-0.37-2.9-0.32-4.33c-0.05-2.88,0.41-5.62,0.99-8.23
		c0.65-2.58,1.53-5.03,2.56-7.31c1.02-2.3,2.18-4.46,3.45-6.52c2.49-4.14,5.42-7.81,8.44-11.3l2.32-2.57
		c0.8-0.82,1.59-1.65,2.4-2.46c1.59-1.64,3.28-3.17,4.96-4.71c1.68-1.53,3.45-2.97,5.18-4.42
		C185.55,234.57,187.34,233.18,189.19,231.86z"
            />
          </g>
          <text
            transform="matrix(1 0 0 1 224.6186 308.2197)"
            style={{
              fill: '#fff',
              fontFamily: 'Caxo',
              fontSize: '102.7485px',
              letterSpacing: '2',
            }}
          >
            CLUBE
          </text>
        </g>
      )

    default:
      return ''
  }
}

export default ({
  className = '',
  color = '',
  onClick,
  type = '',
}: PropsType) => (
  <Icon
    className={`Icon ${className}`.trim()}
    color={color}
    onClick={onClick}
    viewBox="0 0 841.89 595.28"
  >
    {getIcon(type)}
  </Icon>
)
