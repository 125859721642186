import React, { useCallback } from 'react'
import styled, { css } from 'styled-components/macro'
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from '../utils/card'

import date from '../utils/date'
import mask from '../utils/mask'
import theme from '../utils/theme'

const defaultValues = css`
  background: ${theme?.color?.initial} !important;
  border-color: ${theme?.color?.tertiary} !important;
  color: ${theme?.color?.tertiary} !important;
  font-weight: ${theme?.font?.bold};
  height: 50px !important;
  margin-bottom: ${theme?.main?.spacing} !important;
  &::placeholder {
    color: ${theme?.color?.tertiary};
  }
  &:disabled {
    background: ${theme?.color?.quaternary} !important;
  }
  &:active,
  &:focus {
    border-color: ${theme?.color?.primary} !important;
    color: ${theme?.color?.primary} !important;
    &::placeholder {
      color: ${theme?.color?.primary} !important;
    }
  }
`
const Legend = styled.legend`
  color: ${theme?.color?.primary};
  font-size: 125%;
  line-height: 1;
  margin-bottom: ${theme?.main?.spacing};
  text-align: center;
`
const Textarea = styled.textarea`
  ${defaultValues}
`
const Select = styled.select`
  ${defaultValues}
`
const Input = styled.input`
  ${defaultValues},
  &.input[type=number]{
    &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
},
}
`
const Label = styled.label`
  font-size: ${theme?.font?.tiny};
  font-weight: ${theme?.font?.regular};
  height: 0;
  line-height: 0;
  margin: 0;
  text-align: left;
  text-transform: uppercase;
  transform: translate(11px, 12.5px);
  & + input,
  & + select,
  & + textarea {
    padding-top: ${theme?.main?.spacing};
  }
`
const Field = styled.div`
  position: relative;
  &[data-suffix='cm']:after {
    left: 40px;
  }
  &:after {
    color: ${theme?.color?.tertiary} !important;
    content: attr(data-suffix);
    display: block;
    font-weight: ${theme?.font?.bold};
    left: 52px;
    position: absolute;
    top: 61%;
    transform: translateY(-100%);
  }
`

function getRamdomNumber () {
  return Math.floor(Math.random() * Math.floor(999))
}

type PropsType = {
  className?: string | undefined
  defaultValue?: string | undefined
  disabled?: boolean | undefined
  label?: string | undefined
  maxlength?: number | undefined
  name?: string | undefined
  max?: string
  min?: string
  options?: Array<OptionType> | undefined
  onClick?: () => void
  onChange?: any
  placeholder?: string | undefined
  required?: boolean | undefined
  type?: string | undefined
  value?: string | undefined
  pattern?: string | undefined
}

type LabelType = {
  name?: string | undefined
  label?: string | undefined
  type?: string | undefined
}

type OptionType = {
  label: string
  value: any
}

export default ({
  className,
  defaultValue = '',
  disabled,
  label = '',
  maxlength,
  name,
  max = '',
  min = '',
  options,
  onClick,
  onChange,
  placeholder = '',
  required,
  type = '',
  value = '',
  pattern = undefined,
}: PropsType) => {
  const renderLabel = ({ label, name, type }: LabelType) =>
    label && (
      <Label
        className={
          type === 'radio' || type === 'checkbox' ? 'label-inline' : undefined
        }
        htmlFor={name}
      >
        {label}
      </Label>
    )

  switch (type) {
    case 'phone':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            onKeyUp={useCallback(event => {
              event.currentTarget.maxLength = 15
              event.currentTarget.value = mask?.phone(event.currentTarget.value)
              return event
            }, [])}
            placeholder={placeholder}
            required={required}
            value={mask?.phone(defaultValue)}
            autoComplete="tel"
          />
        </Field>
      )

    case 'birthdate':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            max="2000-12-31"
            min="1930-12-31"
            name={name}
            onClick={onClick}
            onChange={onChange}
            onBlur={useCallback(event => {
              if (String(event.currentTarget.value).length < 9)
                event.currentTarget.value = ''
              return event
            }, [])}
            placeholder={placeholder}
            required={required}
            type="date"
          />
        </Field>
      )

    case 'cpf':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            onKeyUp={useCallback(event => {
              event.currentTarget.maxLength = 14
              event.currentTarget.value = mask?.cpf(event.currentTarget.value)
              return event
            }, [])}
            placeholder={placeholder}
            required={required}
            type="text"
            value={mask?.cpf(defaultValue)}
          />
        </Field>
      )

    case 'legend':
      return <Legend>{placeholder || label}</Legend>

    case 'textarea':
      return (
        <Field>
          {renderLabel({ label, name })}
          <Textarea
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            maxLength={maxlength || 2000}
            name={name}
            onClick={onClick}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            value={value}
          />
        </Field>
      )

    case 'select':
      return (
        <Field>
          {renderLabel({ label, name })}
          <Select
            className={className}
            value={defaultValue}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            required={required}
          >
            {options &&
              options?.map(({ label, value }: OptionType, index: number) => (
                <option key={index} value={value}>
                  {label}
                </option>
              ))}
          </Select>
        </Field>
      )

    case 'checkbox':
      return (
        <Field>
          <Input
            className={className}
            defaultValue={defaultValue}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            type={type}
          />
          {renderLabel({ label, name })}
        </Field>
      )

    case 'radio':
      const nameId = `${name}-${getRamdomNumber()}`

      return (
        <Field>
          <Input
            className={className}
            defaultValue={defaultValue}
            id={nameId}
            name={name}
            onClick={onClick}
            onChange={onChange}
            type={type}
          />
          {renderLabel({ name: nameId, label, type })}
        </Field>
      )

    case 'number':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            max={max}
            min={min || '0'}
            name={name}
            onClick={onClick}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            step="any"
            type="number"
          />
        </Field>
      )

    case 'date':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            max={max || date?.getNewDate()}
            name={name}
            onClick={onClick}
            onChange={onChange}
            onBlur={useCallback(event => {
              if (String(event.currentTarget.value).length < 9)
                event.currentTarget.value = ''
              return event
            }, [])}
            placeholder={placeholder}
            required={required}
            type="date"
          />
        </Field>
      )

    case 'password':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            type="password"
            autoComplete="new-password"
          />
        </Field>
      )

    case 'email':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            type="email"
            autoComplete="email"
          />
        </Field>
      )

    case 'cardnumber':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            onKeyUp={useCallback(event => {
              event.currentTarget.value = formatCreditCardNumber(
                event.currentTarget.value,
              )
              return event
            }, [])}
            placeholder={placeholder}
            required={required}
            type="text"
            value={formatCreditCardNumber(defaultValue)}
          />
        </Field>
      )

    case 'cvc':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            onKeyUp={useCallback(event => {
              event.currentTarget.value = formatCVC(event.currentTarget.value)
              return event
            }, [])}
            placeholder={placeholder}
            required={required}
            type="text"
            value={formatCVC(defaultValue)}
          />
        </Field>
      )

    case 'expirationdate':
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            onKeyUp={useCallback(event => {
              event.currentTarget.value = formatExpirationDate(
                event.currentTarget.value,
              )
              return event
            }, [])}
            placeholder={placeholder}
            required={required}
            type="text"
            maxLength={maxlength}
            value={formatExpirationDate(defaultValue)}
          />
        </Field>
      )

    default:
      return (
        <Field>
          {renderLabel({ name, label })}
          <Input
            className={className}
            defaultValue={defaultValue}
            disabled={disabled}
            id={name}
            name={name}
            onClick={onClick}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            type={type || 'text'}
            pattern={pattern}
            maxLength={maxlength}
          />
        </Field>
      )
  }
}
