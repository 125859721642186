import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Title from '../components/Title'
import Search from '../components/Search'

import { getCategories, getCategoriesByType, getDiscounts } from '../utils/api'
import DiscountType from '../types/discount'
import LoadableContent from '../components/LoadableContent'
import { Container, Main } from '../styles'
import Discounts from '../components/Discounts'
import Footer from '../components/Footer'
import CategoryType from '../types/category'

const filterDiscounts = (discounts: DiscountType[], search: string) => {
  if (search) {
    return discounts.filter(discount =>
      discount?.partner?.name.toLowerCase().includes(search.toLowerCase()),
    )
  }

  return discounts
}

export default () => {
  const all: CategoryType = {
    name: 'Todas',
    uid: '-1',
    type: 'Brand',
  }

  const [categories, setCategories] = useState<CategoryType[]>([])
  const [discounts, setDiscounts] = useState<DiscountType[]>([])
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState<CategoryType>(all)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getDiscounts().then((discounts: DiscountType[]) => {
      setDiscounts(
        discounts.sort((a, b) => {
          if (b?.partner == undefined || a?.partner == undefined) return 0
          return a?.partner?.name.localeCompare(b?.partner?.name)
        }),
      )
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    getDiscounts().then(discounts => {
      setDiscounts(
        filterDiscounts(discounts, search)
          .filter(item => {
            return (
              item?.partner?.categoryId == filter?.uid || filter?.uid == '-1'
            )
          })
          .sort((a, b) => {
            if (b?.partner == undefined || a?.partner == undefined) return 0
            return a?.partner?.name.localeCompare(b?.partner?.name)
          }),
      )
      setIsLoading(false)
    })
  }, [search, filter])

  useEffect(() => {
    getCategories().then((categories: CategoryType[]) => {
      categories.push(all)
      setCategories(
        categories.sort((a, b) => {
          if (b == undefined || a == undefined) return 0
          if (a.uid == '-1') return -1
          return a?.name.localeCompare(b?.name)
        }),
      )
    })
  }, [categories])

  const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value)

  const onSearchFilter = (category: CategoryType): void => {
    setFilter(category)
  }

  return (
    <>
      <Header />
      <Main>
        <Container>
          <Title children="Descontos" />
        </Container>
        <Search
          onFilter={onSearchFilter}
          categories={categories}
          placeholder="Pesquisar descontos"
          onChange={onSearchChange}
        />
        <LoadableContent
          loading={isLoading}
          loadingText="Carregando descontos"
          center={false}
        >
          <Discounts discounts={discounts} />
        </LoadableContent>
      </Main>
      <Footer />
    </>
  )
}
