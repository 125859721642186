import React, { useState } from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Container from '../components/Container'
import Icon from '../components/Icon'
import Link from '../components/Link'
import theme from '../utils/theme'
import useAuth from '../hooks/auth'
import useLogout from '../hooks/logout'
import Meta from './meta'
import IconLogo from './IconLogo'

const Header = styled.header`
  background: ${theme?.color?.initial};
  height: 8rem;
  max-width: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  .container {
    align-items: center;
    display: flex;
    height: 8rem;
    justify-content: space-between;
  }
`
const Logo = styled(IconLogo)`
  height: 16rem;
  position: relative;
  z-index: 1;
  &:hover {
    opacity: 0.5;
  }
`
const Navigation = styled.nav`
  align-items: center;
  background: ${theme?.color?.initial};
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  &.false {
    display: none;
  }
  a {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 2rem;
    &:hover {
      opacity: 0.3;
    }
    @media (max-width: 959px) {
      border-top: 1px solid ${theme?.color?.primary};
      height: 4.5rem;
      width: 100%;

      &.account {
        background: ${theme?.color?.primary};
        color: ${theme?.color?.initial};
      }
      &.register {
        background: ${theme.color.details};
      }
    }
  }
  button {
    background: ${theme?.color?.initial};
    border-color: ${theme?.color?.details};
    color: ${theme?.color?.details};
    margin: 0;
    opacity: 1;
    &:hover {
      background: ${theme?.color?.primary};
      border-color: ${theme?.color?.primary};
      color: ${theme?.color?.initial};
      opacity: 1 !important;
    }
    &.logout {
      @media (min-width: 960px) {
        margin-left: 2rem;
      }
      @media (max-width: 959px) {
        border-top: 1px solid ${theme?.color?.secondary};
        border-bottom: 1px solid ${theme?.color?.secondary};
        height: 4.5rem;
        width: 100%;
        background: ${theme.color.details};
        color: ${theme.color.initial};
        border-color: ${theme?.color?.details};
        border-radius: 0;
        font-size: 14px;
      }
    }
  }
  @media (min-width: 960px) {
    align-items: space-between;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    position: relative;
    &.false {
      display: flex;
    }
    a {
      padding: 0;
      padding-left: 2rem;
    }
  }
  @media (max-width: 959px) {
    margin-top: 8rem;
  }
`
const Menu = styled(Icon)`
  cursor: pointer;
  fill: ${theme?.color?.primary};
  height: 3rem;
  position: relative;
  z-index: 1;
  @media (min-width: 960px) {
    display: none;
  }
`
const SignButton = styled(Button)`
  && {
    background: ${theme.color.details};
    border-color: ${theme.color.details};
    color: ${theme.color.initial};
    &:hover {
      background: ${theme.color.primary};
      border-color: ${theme.color.primary};
    }
  }
`

export default () => {
  const [active, setActive] = useState(false)
  const [auth] = useAuth()
  const [handleLogout] = useLogout()

  let layout, button
  if (auth != null) {
    layout = (
      <>
        <Link className="account" href="/my-account">
          Área do Assinante
        </Link>
      </>
    )
    button = (
      <Button className="logout" onClick={() => handleLogout()}>
        Sair
      </Button>
    )
  } else {
    layout = (
      <Link className="account" href="/login">
        Área do Assinante
      </Link>
    )
    button = (
      <Link className="register" href="/registrar">
        <SignButton>ASSINE JÁ!</SignButton>
      </Link>
    )
  }

  return (
    <Header>
      <Meta />
      <Container>
        <Link href="/">
          <Logo type="logo" />
        </Link>
        <div>
          <Menu onClick={() => setActive(!active)} type="menu" />
          <Navigation
            className={String(active)}
            onClick={() => setActive(!active)}
          >
            <Link href="/specialists">Especialistas</Link>
            <Link href="/partners">Marcas Parceiras</Link>
            <Link href="/discounts">Descontos</Link>
            {layout}
            {button}
          </Navigation>
        </div>
      </Container>
    </Header>
  )
}
