import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import { getDiscount, getUser } from '../utils/api'
import DiscountType from '../types/discount'
import LoadableContent from '../components/LoadableContent'
import { Main, PartnerDescription, PartnerImage, PartnerName } from '../styles'
import Footer from '../components/Footer'
import { useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import theme from '../utils/theme'
import { CardContainer } from '../components/CardContainer'
import QRCode from 'react-qr-code'
import useAuth from '../hooks/auth'
import UserType from '../types/user'
import PrintIcon from '@material-ui/icons/Print'
import Linkify from 'linkify-react'
import 'linkify-plugin-mention'

type DiscountParams = {
  id: string
}

const DiscountCardContainer = styled(CardContainer)`
  && {
    max-width: 70rem;
  }
`

const Discount = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 auto;
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  border: 1px solid ${theme?.color?.primary};
  width: 100%;
`

const DiscountDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
`

const DiscountQRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
`

const DiscountPartnerImageContainer = styled.div`
  margin: 2rem;
`
const DiscountPartnerImage = styled(PartnerImage)``

const DiscountPartnerName = styled(PartnerName)`
  margin-bottom: 0;
`

const DiscountPartnerDescription = styled(PartnerDescription)`
  font-weight: ${theme?.font?.bold};
  font-size: 16px;
`

const DiscountDescription = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme?.main?.spacing} 0;
`

const Divider = styled.hr`
  border: 0;
  border-top: 1px solid ${theme?.color?.tertiary};
  margin: 10px;
`

const DiscountUserData = styled.div`
  color: ${theme?.color?.details};
  font-weight: ${theme?.font?.bold};
`

const PrintButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: auto;

  @media print {
    display: none;
  }
`

const PrintButton = styled(PrintIcon)`
  && {
    cursor: pointer;
    color: ${theme?.color?.tertiary};
    font-size: 30px;

    &:hover {
      color: ${theme?.color?.primary};
    }
  }
`

const DiscountFooter = styled.span`
  font-size: ${theme?.font?.small};
  margin-bottom: 1rem;
`

export default () => {
  const [discount, setDiscount] = useState<DiscountType>(null)
  const [user, setUser] = useState<UserType>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [auth] = useAuth()
  const { id } = useParams<DiscountParams>()

  useEffect(() => {
    const fetchDiscountAndUser = async () => {
      const [discount, user] = await Promise.all([
        getDiscount(id),
        getUser(auth?.uid),
      ])
      setDiscount(discount)
      setUser(user)
      setIsLoading(false)
    }
    fetchDiscountAndUser()
  }, [id, auth])

  const print = () => {
    window.print()
  }

  var qrCode
  if (discount?.code == undefined)
    qrCode = 'https://clube-de-vantagens.segonha.com.br/'
  else qrCode = discount?.code

  return (
    <>
      <Header />
      <Main>
        <LoadableContent loading={isLoading} loadingText="Carregando desconto">
          <DiscountCardContainer>
            <PrintButtonContainer>
              <PrintButton onClick={print} />
            </PrintButtonContainer>
            <Discount>
              <DiscountPartnerImageContainer>
                <DiscountPartnerImage
                  src={discount?.partner?.imageUrl}
                  loading="lazy"
                />
              </DiscountPartnerImageContainer>
              <DiscountDetail>
                <DiscountPartnerName>
                  {discount?.partner?.name}
                </DiscountPartnerName>
                <DiscountPartnerDescription
                  title={discount?.partner?.description}
                >
                  {discount?.partner?.description}
                </DiscountPartnerDescription>
                <DiscountDescription>
                  <Linkify
                    options={{
                      formatHref: {
                        mention: href => 'https://www.instagram.com/' + href,
                      },
                      target: '_blank',
                      attributes: {
                        style: {
                          ...{
                            color: `${theme?.color?.primary}`,
                            fontWeight: `${theme?.font?.bold}`,
                          },
                        },
                      },
                    }}
                  >
                    <div>{discount?.description}</div>
                  </Linkify>
                </DiscountDescription>
                <span>Gerado em {new Date().toLocaleDateString()}</span>
              </DiscountDetail>
              <DiscountQRContainer>
                <QRCode value={qrCode} size={150} />
                <DiscountUserData>
                  {user?.firstName} {user?.lastName}
                </DiscountUserData>
                <DiscountUserData>{user?.cpf}</DiscountUserData>
              </DiscountQRContainer>
              <Divider />
              <DiscountFooter>
                *Este desconto aplica-se apenas ao ASSINANTE do Segonha,
                mediante comprovação de identidade.
              </DiscountFooter>
            </Discount>
          </DiscountCardContainer>
        </LoadableContent>
      </Main>
      <Footer />
    </>
  )
}
