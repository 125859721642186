import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import RegisterUserType from '../types/registerUser'
import api from '../utils/api'
import storage from '../utils/storage'

const defaultRegister = {
  firstName: '',
  lastName: '',
  cpf: '',
  email: '',
  phone: '',
  streetAddress: '',
  city: '',
  state: '',
  country: 'Brasil',
  password: '',
  confirmPassword: '',
}

export default (): [
  RegisterUserType,
  (event: any) => void,
  () => void,
  boolean,
] => {
  const history = useHistory()
  const [register, setRegister] = useState(defaultRegister)
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (event: any): void => {
    event?.preventDefault()
    event?.stopPropagation()
    let { name, value } = event?.target
    setRegister({ ...register, [String(name)]: value })
  }

  const handleSubmit = () => {
    setIsLoading(true)

    api.register(register).then(response => {
      if (!response?.uid) {
        setIsLoading(false)
        return
      }

      storage.set('userId', response.uid)

      history?.push(`/pagamento`)
    })
  }

  return [register, handleChange, handleSubmit, isLoading]
}
