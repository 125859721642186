import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../utils/theme'
import IconLogo from '../IconLogo'

const PaymentDescription = styled.form`
  background: ${theme?.color?.primary};
  border-radius: ${theme?.main?.radius};
  max-width: 500px;
  padding: calc(${theme?.main?.spacing} / 2) ${theme?.main?.spacing}
    ${theme?.main?.spacing};
  text-align: center;
  width: 100%;
  margin-right: 10px;
  z-index: 1;
  @media (max-width: 400px) {
    margin-right: 0;
  }
`

const Title = styled.h2`
  color: ${theme?.color?.details};
  font-weight: ${theme?.font?.regular};
  font-size: 4.5rem;
  line-height: 6rem;
  text-align: left;
`

export const Description = styled.div`
  color: ${theme?.color?.details};
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 2rem;
  position: relative;
  text-align: left;
`

const Highlight = styled.span`
  color: ${theme.color.initial};
  font-weight: ${theme.font.bold};
`

const PriceTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  padding-left: 0;
`

const DivBlock3 = styled.div`
  display: flex;
  width: 114px;
  margin-top: 5px;
  justify-content: space-between;
  align-items: flex-start;
`

const DivBlock4 = styled.div`
  display: flex;
  flex-direction: column;
`

const Heading2 = styled.h2`
  font-size: 50px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 0;
`

const Heading4 = styled.h4`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`

const MoneyMark = styled.div`
  margin-top: -4px;
  margin-right: 5px;
`

const Logo = styled(IconLogo)`
  height: 20rem;
  position: relative;
  margin-top: -6rem;
  margin-bottom: -6rem;
`

export default () => (
  <PaymentDescription>
    <Logo type="logo-back" />
    <Title>
      Você não está mais só nesse mar de <Highlight>dúvidas</Highlight> que é
      ter filhos...
    </Title>
    <Description>
      Conte com a ajuda de especialistas, descontos em atendimentos e marcas
      incríveis
    </Description>
    <Description>
      <Highlight>
        Acesso ilimitado a todo o conteúdo por um ano
        <PriceTag>
          <DivBlock3>
            <MoneyMark>
              <div style={{ height: '20px' }}>12x</div>
              <Heading4>R$</Heading4>
            </MoneyMark>
            <Heading2>20</Heading2>
            <DivBlock4>
              <Heading4>,99</Heading4>
            </DivBlock4>
          </DivBlock3>
        </PriceTag>
      </Highlight>
    </Description>
  </PaymentDescription>
)
