import React from 'react'

import styled from 'styled-components/macro'

import usePayment from '../hooks/payment'

import Payment from '../components/Register/Payment'
import { validatePayment } from '../utils/registration'
import LoadableContent from '../components/LoadableContent'
import Meta from '../components/meta'

type PropType = {
  showDescription?: boolean
  centerLoading?: boolean
  height?: string
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

export default ({
  showDescription = true,
  centerLoading = true,
  height = '100vh',
}: PropType) => {
  const [card, handleChange, handleSubmit, isLoading] = usePayment()

  const handlePayment = (event: any) => {
    event?.preventDefault()
    event?.stopPropagation()

    if (validatePayment(card)) {
      handleSubmit()
    }
  }

  return (
    <LoadableContent
      loading={isLoading}
      loadingText="Processando pagamento"
      center={centerLoading}
    >
      <Meta />
      <Container style={{ height: height }}>
        <Payment
          card={card}
          handleChange={handleChange}
          handleSubmit={handlePayment}
          showDescription={showDescription}
        />
      </Container>
    </LoadableContent>
  )
}
