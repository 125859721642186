import React from 'react'
import styled from 'styled-components/macro'

import useRegister from '../hooks/register'

import Register from '../components/Register/Register'
import { validateRegisterUser } from '../utils/registration'
import LoadableContent from '../components/LoadableContent'
import Meta from '../components/meta'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export default () => {
  const [user, handleChange, handleSubmit, isLoading] = useRegister()

  const handleRegister = (event: any) => {
    event?.preventDefault()
    event?.stopPropagation()

    if (validateRegisterUser(user)) {
      handleSubmit()
    }
  }

  return (
    <LoadableContent
      loading={isLoading}
      loadingText="Realizando o cadastro antes de prosseguir para pagamento"
    >
      <Meta />
      <Container>
        <Register
          user={user}
          handleChange={handleChange}
          handleSubmit={handleRegister}
        />
      </Container>
    </LoadableContent>
  )
}
