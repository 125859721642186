import SubscriptionType, { hasExpired } from './subscription'

type UserType = {
  firstName: string
  lastName: string
  cpf: string
  email: string
  phone: string
  cep: string
  streetAddress: string
  streetNumber: string
  neighborhood: string
  city: string
  state: string
  country: string
  subscriptions?: Array<SubscriptionType> | null
  uid: string
  createdAt: string
  updatedAt: string
} | null

export function hasValidSubscription (user: UserType): boolean {
  if (!user?.subscriptions || typeof user?.subscriptions === 'undefined') {
    return false
  }
  var result = false
  Object.values(user?.subscriptions).forEach(item => {
    if (!hasExpired(item)) {
      result = true
      return
    }
  })

  return result
}

export function hasCompletedIntegration (user: UserType): boolean {
  if (!user?.subscriptions || typeof user?.subscriptions === 'undefined') {
    return true
  }
  var result = Object.values(user?.subscriptions).find(
    item => !hasExpired(item),
  )

  return (
    result != undefined &&
    result?.hasCompletedIntegration != undefined &&
    result?.hasCompletedIntegration
  )
}

export default UserType
