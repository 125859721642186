import React from 'react'
import styled from 'styled-components/macro'
import CardType from '../../types/card'
import theme from '../../utils/theme'
import Button from '../Button'
import Column from '../Column'
import Field from '../Field'
import Row from '../Row'
import Card from './Card'
import PaymentDescription from './PaymentDescription'

type PropType = {
  card: CardType
  handleChange: (event: any) => void
  handleSubmit: (event: any) => void
  showDescription?: boolean
}

const Payment = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  max-width: 500px;
  padding: calc(${theme?.main?.spacing} / 2) ${theme?.main?.spacing} 5px;
  text-align: center;
  width: 100%;
`

const PaymentButton = styled(Button)`
  background: ${theme?.color?.details};
  border-color: ${theme.color.details};
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    background: ${theme.color.primary};
    border-color: ${theme.color.primary};
    &:disabled {
      background: ${theme.color.details};
      border-color: ${theme.color.details};
    }
  }
`

export default ({
  card,
  handleChange,
  handleSubmit,
  showDescription = true,
}: PropType) => (
  <>
    {showDescription && <PaymentDescription />}
    <Payment onSubmit={handleSubmit}>
      <Card
        cvc={card?.cvc}
        expiry={card?.expiry}
        name={card?.name}
        number={card?.number}
      />
      <Field
        defaultValue={card?.number}
        label="Número do cartão*"
        name="number"
        onChange={handleChange}
        type="cardnumber"
        pattern="[\d| ]{16,22}"
      />
      <Field
        defaultValue={card?.name}
        label="Nome do proprietário do cartão*"
        name="name"
        onChange={handleChange}
        type="text"
        maxlength={100}
      />
      <Row>
        <Column>
          <Field
            defaultValue={card?.expiry}
            label="Data de vencimento*"
            name="expiry"
            onChange={handleChange}
            type="expirationdate"
            pattern="\d\d/\d\d\d\d"
            maxlength={7}
          />
        </Column>
        <Column>
          <Field
            defaultValue={card?.cvc}
            label="Código de segurança*"
            name="cvc"
            onChange={handleChange}
            type="cvc"
            pattern="\d{3,4}"
          />
        </Column>
      </Row>
      <PaymentButton size="large">Finalizar</PaymentButton>
    </Payment>
  </>
)
