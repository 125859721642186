import React from 'react'
import styled from 'styled-components/macro'
import Payment from '../../pages/payment'
import theme from '../../utils/theme'
import Icon from '../Icon'

type PropType = {
  open: boolean
  onClose: () => void
}

const SubscriptionModal = styled.div`
  background: rgba(0, 0, 0, 0.85);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
`
const ModalContainer = styled.div`
  background: ${theme?.color?.initial};
  left: 50%;
  min-height: 40rem;
  max-height: 90vh;
  max-width: 80rem;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
`
const ModalHeader = styled.div`
  align-items: center;
  background: ${theme?.color?.primary};
  display: flex;
  height: 5rem;
  justify-content: center;
  position: relative;
`
const ModalTitle = styled.div`
  color: ${theme?.color?.initial};
  font-weight: ${theme?.font?.bold};
  text-transform: uppercase;
`
const ModalContent = styled.div`
  color: ${theme?.color?.tertiary};
  max-height: 90vh;
  overflow: hidden;
  overflow-y: scroll;
  padding: 4rem 3rem 6rem;
  position: relative;
`
const ModalClose = styled.div`
  cursor: pointer;
  position: absolute;
  right: 2rem;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.3;
    transition: opacity 0.3s;
  }
  svg {
    fill: ${theme?.color?.initial};
  }
`

export default ({ open, onClose }: PropType) => {
  if (!open) {
    return null
  }

  return (
    <SubscriptionModal>
      <ModalContainer>
        <ModalHeader>
          <ModalClose onClick={onClose}>
            <Icon type="close" />
          </ModalClose>
          <ModalTitle>Adicionar assinatura</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <Payment showDescription={false} centerLoading={false} height={''} />
        </ModalContent>
      </ModalContainer>
    </SubscriptionModal>
  )
}
