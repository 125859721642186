import React from 'react'
import DiscountType from '../types/discount'
import { CardContainer } from './CardContainer'
import DiscountCard from './DiscountCard'

type PropType = {
  discounts: DiscountType[]
}

export default ({ discounts }: PropType) => {
  return (
    <CardContainer>
      {discounts &&
        discounts.map(discount => (
          <DiscountCard key={discount?.uid} discount={discount} />
        ))}
    </CardContainer>
  )
}
