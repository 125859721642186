import { useState } from 'react'
import CardType from '../types/card'
import {
  PagSeguroChargeType,
  PagSeguroType,
  PagSeguroChargeStatus,
  PagSeguroOrderType,
} from '../types/pagseguro'
import { charge } from '../utils/pagseguro'
import { useScript } from './script'
import storage from '../utils/storage'
import RegisterSubscriptionType from '../types/registerSubscription'
import { getUser, registerSubscription } from '../utils/api'
import { useHistory } from 'react-router-dom'
import dateUtils from '../utils/date'
import { pagSeguroWarning } from '../utils/warning'
import {
  SubscriptionPaymentMethod,
  SubscriptionStatus,
} from '../types/subscription'
import useAuth from '../hooks/auth'
import UserType from '../types/user'
import { saveBeneficiary } from '../utils/epharma'

const PAGSEGURO_URL =
  'https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js'

const defaultRegister = {
  number: '',
  name: '',
  expiry: '',
  cvc: '',
}

export default (): [CardType, (event: any) => void, () => void, boolean] => {
  const [payment, setPayment] = useState(defaultRegister)
  const PagSeguro = useScript(PAGSEGURO_URL, 'PagSeguro') as PagSeguroType
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [auth] = useAuth()

  const handleChange = (event: any): void => {
    event?.preventDefault()
    event?.stopPropagation()
    let { name, value } = event?.target
    setPayment({ ...payment, [String(name)]: value })
  }

  const saveSubscription = async (
    user: UserType,
    charge: PagSeguroOrderType,
  ) => {
    let userId = storage.get('userId')
    let redirectToMainPage = true

    if (Object.keys(userId).length === 0) {
      userId = auth?.uid
      redirectToMainPage = false // Usuário está logado, portanto a assinatura está sendo feita na página de assinatura
    }

    const oneYearDate = new Date()
    oneYearDate.setFullYear(oneYearDate.getFullYear() + 1)

    const subscription: RegisterSubscriptionType = {
      userId: userId,
      status: SubscriptionStatus.ACTIVE,
      startDate: dateUtils.getNewDate(),
      endDate: dateUtils.parseCalendar(oneYearDate),
      value: 'R$ 251,88',
      paymentMethod: SubscriptionPaymentMethod.CREDIT_CARD,
      referenceId: charge.id,
      hasCompletedIntegration: false,
    }

    subscription.hasCompletedIntegration = await saveBeneficiary(
      user,
      subscription,
    )
    registerSubscription(subscription).then(response => {
      if (!response?.uid) {
        setIsLoading(false)
        return
      }

      storage.remove('userId')

      if (redirectToMainPage) {
        history.push('/success')
      } else {
        history.go(0)
      }
    })
  }

  const submitCallback = async (
    user: UserType,
    response: PagSeguroOrderType | null,
  ): Promise<void> => {
    if (response === null) {
      setIsLoading(false)
      return
    }

    if (response.charges[0]?.status === PagSeguroChargeStatus.DECLINED) {
      pagSeguroWarning([{ code: 'PAYMENT_DECLINED' }])
      setIsLoading(false)
      return
    }

    if (response.charges[0]?.status === PagSeguroChargeStatus.IN_ANALYSIS) {
      pagSeguroWarning([{ code: 'PAYMENT_IN_ANALYSIS' }])
      setIsLoading(false)
      return
    }

    saveSubscription(user, response)
  }

  const handleSubmit = (): void => {
    setIsLoading(true)

    getUser(auth?.uid).then((user: UserType) => {
      charge({
        pagSeguroClient: PagSeguro,
        holder: payment.name,
        number: payment.number.replace(/\s/g, ''),
        expiration: payment.expiry,
        cvv: payment.cvc,
        customer: user,
        callback: submitCallback,
      })
    })
  }

  return [payment, handleChange, handleSubmit, isLoading]
}
