import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import LoadableContent from '../components/LoadableContent'
import { Container, Main } from '../styles'
import useAccount from '../hooks/account'
import Account from '../components/Account/Account'
import Subscription from '../components/Account/Subscription'

export default () => {
  const [user, handleChange, handleSubmit, isLoading, isSaving] = useAccount()

  return (
    <LoadableContent loading={isLoading}>
      <Header />
      <Main>
        <Container>
          <Subscription subscriptions={user?.subscriptions} />
          <Account
            user={user}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isSaving={isSaving}
          />
        </Container>
      </Main>
      <Footer />
    </LoadableContent>
  )
}
