import ErrorType from './error'

export type PagSeguroEncryptType = {
  publicKey: string
  holder: string
  number: string
  expMonth: string
  expYear: string
  securityCode: string
}

export type PagSeguroType = {
  PagSeguro: {
    env: any
    setUp: (parameters: { env: string }) => void
    encryptCard: (card: PagSeguroEncryptType) => PagSeguroCardType
  }
}

export type PagSeguroCardType = {
  encryptedCard: string
  hasErrors: boolean
  errors: ErrorType[]
}

export enum PagSeguroChargeStatus {
  AUTHORIZED = 'AUTHORIZED',
  PAID = 'PAID',
  IN_ANALYSIS = 'IN_ANALYSIS',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
}

export type PagSeguroChargeType = {
  id: string
  status: PagSeguroChargeStatus
}

export type PagSeguroOrderType = {
  id: string
  charges: PagSeguroChargeType[]
}
