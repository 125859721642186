import React from 'react'

import styled from 'styled-components/macro'
import Button from '../components/Button'
import Link from '../components/Link'
import Logo from '../components/Logo'
import Meta from '../components/meta'
import Row from '../components/Row'
import theme from '../utils/theme'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`

const Description = styled.div`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  max-width: 500px;
  padding: calc(${theme?.main?.spacing} / 2) ${theme?.main?.spacing} 0
    ${theme?.main?.spacing};
  text-align: center;
  @media (max-width: 400px) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  @media (min-width: 401px) {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  width: 100%;
  z-index: 1;
`

const HomeButton = styled(Button)`
  background: ${theme?.color?.primary};
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-transform: uppercase;
`

export default () => {
  return (
    <Container style={{ height: '100vh' }}>
      <Meta />
      <Description>
        <Logo />
        <Row>
          Obrigado, seu cadastro foi finalizado com sucesso! Agora você pode
          aproveitar todas as vantagens do Segonha.
        </Row>
        <Link href="/discounts">
          <HomeButton size="large">Descontos</HomeButton>
        </Link>
      </Description>
    </Container>
  )
}
