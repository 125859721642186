import React, { useState } from 'react'
import styled from 'styled-components'
import CategoryType from '../types/category'
import theme from '../utils/theme'
import Dropdown from './Dropdown'

const Button = styled.button`
  position: relative;
  padding: 2.5rem 2.5rem !important;
  color: ${theme.color.initial} !important;
  background-color: ${theme.color.primary} !important;
  border-color: ${theme.color.primary};
  font-weight: ${theme.font.bold};
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 14px;
  border-radius: 10px !important;
  cursor: pointer;
  margin-right: 2.5rem;
  line-height: 0rem;
  &:hover {
    color: ${theme.color.primary};
    background-color: ${theme.color.primary};
  }
  .active {
    color: ${theme.color.tertiary};
    background-color: ${theme.color.initial};
  }
  @media (max-width: 430px) {
    max-width: 250px;
    font-size: 12px;
    padding: 0.5rem 1rem !important;
    margin-right: 0rem;
  }
`

type Props = {
  onFilter: Function
  categories: CategoryType[]
}

export default ({ onFilter, categories }: Props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const [selectCategory, setSelectCategory] = useState<CategoryType>(null)

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown)
  }

  const dismissHandler = (event: React.FocusEvent<HTMLButtonElement>): void => {
    if (event.currentTarget === event.target) {
      setShowDropdown(false)
    }
  }

  const categorySelection = (category: CategoryType): void => {
    setSelectCategory(category)
    onFilter(category)
  }

  return (
    <Button
      className={showDropdown ? 'active' : undefined}
      onClick={(): void => toggleDropdown()}
      onBlur={(e: React.FocusEvent<HTMLButtonElement>): void =>
        dismissHandler(e)
      }
    >
      <div>
        {selectCategory != undefined && selectCategory.uid != '-1'
          ? selectCategory.name
          : 'Categorias'}{' '}
      </div>
      {showDropdown && (
        <Dropdown
          categories={categories}
          showDropdown={false}
          toggleDropdown={(): void => toggleDropdown()}
          selection={categorySelection}
        />
      )}
    </Button>
  )
}
