import React from 'react'
import styled from 'styled-components/macro'

import About from './About'
import Column from './Column'
import Row from './Row'
import Video from './Video'

const Main = styled.main`
  overflow: hidden;
  padding-top: 8rem;
`

export default () => (
  <Main>
    <Row>
      <Column>
        <About />
      </Column>
      <Column>
        <Video />
      </Column>
    </Row>
  </Main>
)
