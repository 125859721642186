import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Description } from '../../styles'
import SubscriptionType, { hasExpired } from '../../types/subscription'
import theme from '../../utils/theme'
import Button from '../Button'
import Row from '../Row'
import SubscriptionModal from './SubscriptionModal'
import useSubscriptions from '../../hooks/subscription'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TablePaper,
  TableRow,
} from '../Table'

type PropType = {
  subscriptions?: any
}

const SubscriptionsTableWrapper = styled.div`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  display: block;
  padding: calc(${theme?.main?.spacing} / 2) ${theme?.main?.spacing}
    calc(${theme?.main?.spacing} / 5);
  text-align: center;
  width: 100%;
`

const EmptySubscription = styled(Description)`
  && {
    padding: 2rem;
    margin-bottom: 0;
  }
`

const SubscriptionsTitle = styled.h2`
  color: ${theme?.color?.primary};
  font-weight: ${theme?.font?.bold};
  line-height: 2;
  margin: 0;
  padding: 0 1rem;
`

const AddSubscriptionButton = styled(Button)`
  background: ${theme?.color?.primary};
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
`

export default ({ subscriptions }: PropType) => {
  const [isAddSubscriptionModalOpen, setIsAddSubscriptionModalOpen] = useState(
    false,
  )
  const [subscriptionsArray, isValid] = useSubscriptions(subscriptions)

  const showAddSubscriptionButton = !subscriptionsArray || !isValid

  return (
    <>
      <SubscriptionsTableWrapper>
        {!subscriptionsArray && (
          <EmptySubscription>Nenhuma assinatura encontrada</EmptySubscription>
        )}
        {subscriptionsArray && (
          <>
            <Row>
              <SubscriptionsTitle>Assinaturas</SubscriptionsTitle>
            </Row>
            <TablePaper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader align="center">Status</TableHeader>
                    <TableHeader align="center">Data de Início</TableHeader>
                    <TableHeader align="center">Data de Término</TableHeader>
                    <TableHeader align="center">Modo de Pagamento</TableHeader>
                    <TableHeader align="center">Valor (R$)</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {subscriptionsArray?.map((subscription: SubscriptionType) => (
                    <TableRow key={subscription?.uid}>
                      <TableCell align="center">
                        {hasExpired(subscription) ? 'Vencida' : 'Ativa'}
                      </TableCell>
                      <TableCell align="center">
                        {subscription?.startDate}
                      </TableCell>
                      <TableCell align="center">
                        {subscription?.endDate}
                      </TableCell>
                      <TableCell align="center">
                        {subscription?.paymentMethod}
                      </TableCell>
                      <TableCell align="center">
                        {subscription?.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TablePaper>
          </>
        )}
        {showAddSubscriptionButton && (
          <>
            <AddSubscriptionButton
              onClick={() =>
                setIsAddSubscriptionModalOpen(!isAddSubscriptionModalOpen)
              }
            >
              Adicionar assinatura
            </AddSubscriptionButton>
          </>
        )}
      </SubscriptionsTableWrapper>
      <SubscriptionModal
        open={isAddSubscriptionModalOpen}
        onClose={() => setIsAddSubscriptionModalOpen(false)}
      />
    </>
  )
}
