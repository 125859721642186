import React from 'react'
import PartnerType from '../types/partner'
import { CardContainer } from './CardContainer'
import PartnerCard from './PartnerCard'

type PropType = {
  partners: PartnerType[]
}

export default ({ partners }: PropType) => {
  return (
    <CardContainer>
      {partners &&
        partners.map(partner => (
          <PartnerCard key={partner?.uid} partner={partner} />
        ))}
    </CardContainer>
  )
}
