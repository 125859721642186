import React, { useEffect, useState } from 'react'

import Header from '../components/Header'
import Title from '../components/Title'
import Search from '../components/Search'

import { getCategoriesByType, getSpecialists } from '../utils/api'
import PartnerType from '../types/partner'
import Partners from '../components/Partners'
import LoadableContent from '../components/LoadableContent'
import { Container, Description, Main } from '../styles'
import Footer from '../components/Footer'
import CategoryType from '../types/category'

export default () => {
  const all: CategoryType = {
    name: 'Todas',
    uid: '-1',
    type: 'Brand',
  }

  const [categories, setCategories] = useState<CategoryType[]>([])
  const [specialists, setSpecialists] = useState<PartnerType[]>([])
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState<CategoryType>(all)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getSpecialists().then((specialists: PartnerType[]) => {
      setSpecialists(
        specialists.sort((a, b) => {
          if (b == undefined || a == undefined) return 0
          return a?.name.localeCompare(b?.name)
        }),
      )
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    getSpecialists(search.toLowerCase()).then((specialists: PartnerType[]) => {
      setSpecialists(
        specialists
          .filter(item => {
            return item?.categoryId == filter?.uid || filter?.uid == '-1'
          })
          .sort((a, b) => {
            if (b == undefined || a == undefined) return 0
            return a?.name.localeCompare(b?.name)
          }),
      )
      setIsLoading(false)
    })
  }, [search, filter])

  useEffect(() => {
    getCategoriesByType('Specialist').then((categories: CategoryType[]) => {
      categories.push(all)
      setCategories(
        categories.sort((a, b) => {
          if (b == undefined || a == undefined) return 0
          if (a.uid == '-1') return -1
          return a?.name.localeCompare(b?.name)
        }),
      )
    })
  }, [categories])

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value)

  const onSearchFilter = (category: CategoryType): void => {
    setFilter(category)
  }

  return (
    <>
      <Header />
      <Main>
        <Container>
          <Title children="Especialistas" />
          <Description children="Conte com a curadoria, descontos e dicas dos melhores especialistas, chancelados e escolhidos a dedo por nossa equipe." />
        </Container>
        <Search
          onFilter={onSearchFilter}
          categories={categories}
          placeholder="Pesquisar especialistas"
          onChange={onSearchChange}
        />
        <LoadableContent
          loading={isLoading}
          loadingText="Carregando especialistas"
          center={false}
        >
          <Partners partners={specialists} />
        </LoadableContent>
      </Main>
      <Footer />
    </>
  )
}
