import React from 'react'
import styled from 'styled-components/macro'
import { PartnerName, PartnerImage, PartnerDescription } from '../styles'
import PartnerType from '../types/partner'
import theme from '../utils/theme'

type PropType = {
  partner: PartnerType
}

const CardWrapper = styled.a`
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  border-style: solid;
  border-color: ${theme.color.primary};
  border-width: 5px;
  padding: 8px;
  background: ${theme.color.initial};
  width: 220px;
  height: 250px;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 430px) {
    width: 150px;
    height: 250px;
  }
`

const CardImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: 10px;
  min-height: 100px;
  max-height: 100px;
  @media (max-width: 430px) {
    margin-top: 1px;
  }
`

const CardTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
`

export default ({ partner }: PropType) => (
  <CardWrapper
    href={partner?.socialMedia}
    target="_blank"
    rel="noopener noreferrer"
  >
    <CardImageWrapper>
      <PartnerImage src={partner?.imageUrl} loading="lazy" />
    </CardImageWrapper>
    <CardTextWrapper>
      <PartnerName>{partner?.name}</PartnerName>
      <PartnerDescription title={partner?.description}>
        {partner?.description}
      </PartnerDescription>
    </CardTextWrapper>
  </CardWrapper>
)
