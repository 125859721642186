import RegisterUserType from '../types/registerUser'
import warning from './warning'
import cpfUtils from '../utils/cpf'
import phoneUtil from '../utils/phone'
import CardType from '../types/card'

export const validateRegisterUser = (register: RegisterUserType): boolean => {
  if (
    !register?.firstName ||
    !register?.lastName ||
    !register?.cpf ||
    !register?.email ||
    !register?.phone ||
    !register?.password
  ) {
    warning({ code: 'register/missing-field' })
    return false
  }
  if (!cpfUtils.validation(register?.cpf)) {
    warning({ code: 'auth/invalid-cpf' })
    return false
  }
  if (!phoneUtil.validation(register?.phone)) {
    warning({ code: 'auth/invalid-phone' })
    return false
  }
  if (register?.password.length < 6) {
    warning({ code: 'auth/weak-password' })
    return false
  }
  if (register?.password !== register?.confirmPassword) {
    warning({ code: 'register/invalid-confirm-password' })
    return false
  }
  return true
}

export const validatePayment = (card: CardType): boolean => {
  if (!card?.number || !card?.name || !card?.expiry || !card?.cvc) {
    warning({ code: 'register/missing-field' })
    return false
  }

  if (card?.expiry.length !== 7) {
    warning({ code: 'payment/expiration-size-invalid' })
    return false
  }

  return true
}
