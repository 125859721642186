import ErrorType from '../types/error'
import dialog from '../utils/dialog'

const defaultMessage =
  'Há algo errado com nosso servidor. Por favor, tente novamente mais tarde'

const defaultPagSeguroMessage =
  'Houve um erro ao processar o pagamento. Por favor, tente novamente mais tarde.'

const parseError = (code: string): string => {
  switch (code) {
    case 'auth/email-already-in-use':
      return 'O endereço de e-mail já está cadastrado.'
    case 'auth/missing-field':
      return 'Por favor, preencha todos os campos requeridos'
    case 'auth/invalid-email':
      return 'Email inválido'
    case 'auth/invalid-birthdate':
      return 'Data de nascimento inválido'
    case 'auth/invalid-cpf':
      return 'CPF inválido'
    case 'auth/duplicate-cpf':
      return 'Este CPF já está em uso. Por favor, verifique se o paciente ja foi inserido anteriormente.'
    case 'auth/invalid-phone':
      return 'Telefone inválido'
    case 'auth/wrong-password':
      return 'Senha inválida'
    case 'auth/user-not-found':
      return 'Usuário inválido ou excluído'
    case 'auth/recover-password':
      return 'Por favor, verifique seu email! Enviamos um link para você recuperar sua senha.'
    case 'auth/email-updated':
      return 'Seu email foi atualizado com sucesso'
    case 'auth/weak-password':
      return 'A senha deve ter pelo menos 6 caracteres'
    case 'register/missing-field':
      return 'Por favor, preencha os campos requeridos com asterisco (*).'
    case 'register/invalid-name':
      return 'Nome inválido'
    case 'register/invalid-email':
      return 'Email inválido'
    case 'register/invalid-cpf':
      return 'CPF inválido'
    case 'register/invalid-phone':
      return 'Telefone inválido'
    case 'register/invalid-confirm-password':
      return 'Confirmação de senha inválida'
    case 'register/invalid-height':
      return 'Altura inválido'
    case 'register/invalid-weight':
      return 'Peso inválido'
    case 'data/missing-field':
      return 'Obstetra não encontrado'
    case 'notes/invalid-user':
      return 'Usuário inválido'
    case 'notes/invalid-content':
      return 'Conteúdo inválido'
    case 'auth/unauthorized-email':
      return 'O email inserido não está autorizado para cadastro. Por favor, verifique com a equipe técnica do Segonha.'
    case 'payment/expiration-size-invalid':
      return 'Data de vencimento inválida. Por favor, verifique se o valor está no formato MM/AAAA (Ano com 4 dígitos).'
    default:
      return defaultMessage
  }
}

const parsePagSeguroError = (code: string): string => {
  switch (code) {
    case 'INVALID_NUMBER':
      return 'Número do cartão inválido'
    case 'INVALID_SECURITY_CODE':
      return 'CVC inválido. Você deve inserir os 3, 4 ou nenhum dígitos.'
    case 'INVALID_EXPIRATION_MONTH':
      return 'Mês de expiração inválido. Você deve inserir um mês entre 01 e 12.'
    case 'INVALID_EXPIRATION_YEAR':
      return 'Ano de expiração inválido. Você deve inserir um valor com 4 dígitos.'
    case 'INVALID_HOLDER':
      return 'Nome do titular inválido.'
    case 'PAYMENT_DECLINED':
      return 'Pagamento Negado. Contate a central do seu cartão.'
    case 'PAYMENT_IN_ANALYSIS':
      return 'Pagamento em Analise.'
    default:
      return defaultPagSeguroMessage
  }
}

export default (error: ErrorType): void => {
  const message = !error?.code ? defaultMessage : parseError(error.code)
  dialog?.alert(message)
}

export const pagSeguroWarning = (errors?: ErrorType[]): void => {
  const message =
    errors?.map(error => parsePagSeguroError(error.code)).join(' ') ||
    defaultPagSeguroMessage
  dialog?.alert(message)
}
