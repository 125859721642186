import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  children: string
}

const Title = styled.h2`
  color: ${theme?.color?.primary};
  font-weight: ${theme?.font?.bold};
  line-height: 2;
  margin: 0;
  @media (max-width: 430px) {
    font-size: 18px;
    line-height: 1;
    margin-bottom: 10px;
  }
`

export default ({ children }: PropType) => <Title>{children}</Title>
