import React from 'react'
import styled from 'styled-components/macro'

import Button from '../components/Button'
import Field from '../components/Field'
import Logo from '../components/Logo'
import Meta from '../components/meta'
import useRecover from '../hooks/recover'
import theme from '../utils/theme'

const Recover = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  display: block;
  left: 50%;
  max-width: 350px;
  padding: calc(${theme?.main?.spacing} / 2) ${theme?.main?.spacing}
    ${theme?.main?.spacing};
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  button {
    bottom: calc(${theme?.main?.spacing} * -3.25);
    left: 0;
    position: absolute;
  }
  a {
    margin: calc(${theme?.main?.spacing} * 0.5) 0;
  }
`

export default () => {
  const [recover, handleChange, handleSubmit] = useRecover()

  return (
    <Recover onSubmit={handleSubmit}>
      <Meta />
      <Logo />
      <Field
        defaultValue={recover?.email}
        name="email"
        placeholder="Digite seu email"
        onChange={handleChange}
        type="email"
      />
      <Button size="large">Recuperar senha</Button>
    </Recover>
  )
}
