type SubscriptionType = {
  userId: string
  status: SubscriptionStatus
  startDate: string
  endDate: string
  value: string
  paymentMethod: SubscriptionPaymentMethod
  uid: string
  hasCompletedIntegration: boolean
} | null

export enum SubscriptionStatus {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
  CANCELED = 'Cancelado',
  AWAITING_PAYMENT = 'Aguardando Pagamento',
}

export enum SubscriptionPaymentMethod {
  CREDIT_CARD = 'Cartão de Crédito',
  DEBIT_CARD = 'Cartão de Débito',
  BANK_SLIP = 'Boleto Bancário',
}

export function hasExpired (subs: SubscriptionType): boolean {
  let endDateAux
  if (!subs?.endDate || typeof subs?.endDate !== 'string') {
    endDateAux = Date.now().valueOf()
  } else {
    endDateAux = Date.parse(subs?.endDate)
  }
  return Date.now().valueOf() > endDateAux
}

export default SubscriptionType
