import React, { useState } from 'react'
import styled from 'styled-components/macro'
import RegisterUserType from '../../types/registerUser'
import theme from '../../utils/theme'
import Button from '../Button'
import Checkbox from '../Checkbox'
import Column from '../Column'
import Field from '../Field'
import Logo from '../Logo'
import Modal from '../Modal'
import Row from '../Row'
import StatesDropdown from './StatesDropdown'

type PropType = {
  user: RegisterUserType
  handleChange: (event: any) => void
  handleSubmit: (event: any) => void
}

const Register = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  max-width: 500px;
  padding: 0 ${theme?.main?.spacing};
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  a {
    margin: calc(${theme?.main?.spacing} * 0.5) 0;
  }
`

const RegisterButton = styled(Button)`
  background: ${theme?.color?.details};
  border-color: ${theme.color.details};
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  &:hover {
    background: ${theme.color.primary};
    border-color: ${theme.color.primary};
    &:disabled {
      background: ${theme.color.details};
      border-color: ${theme.color.details};
    }
  }
`

const LoginLinkColumn = styled(Column)`
  padding-bottom: ${theme?.main?.spacing} !important;
`

const Link = styled.a`
  color: ${theme?.color?.primary};
  transition: all 0.2s linear;
  font-weight: bold;
`

const CheckboxColumn = styled(Column)`
  && {
    text-align: left;
  }
`

const Label = styled.label`
  margin-bottom: 1.5rem;

  span {
    font-size: 13px;
    margin-left: 8px;
  }
`

export default ({ user, handleChange, handleSubmit }: PropType) => {
  const [showAdhesionTerm, serShowAdhesionTerm] = useState(false)
  const [agreeToAdhesionTerms, setAgreeToAdhesionTerms] = useState(false)

  const handleAdhesionTermsAgreementChange = (event: any) => {
    setAgreeToAdhesionTerms(event.target.checked)
  }

  const handleAdhesionTermsLinkClick = (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    serShowAdhesionTerm(true)
  }

  return (
    <>
      <Register onSubmit={handleSubmit}>
        <Logo />
        <Row>
          <Column>
            <Field
              defaultValue={user?.firstName}
              label="Primeiro nome*"
              name="firstName"
              onChange={handleChange}
              type="text"
            />
          </Column>
          <Column>
            <Field
              defaultValue={user?.lastName}
              label="Sobrenome*"
              name="lastName"
              onChange={handleChange}
              type="text"
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Field
              defaultValue={user?.cpf}
              label="CPF*"
              name="cpf"
              onChange={handleChange}
              type="cpf"
            />
          </Column>
          <Column>
            <Field
              defaultValue={user?.phone}
              label="Telefone*"
              name="phone"
              onChange={handleChange}
              type="phone"
            />
          </Column>
        </Row>
        <Field
          defaultValue={user?.streetAddress}
          label="Endereço"
          name="streetAddress"
          onChange={handleChange}
          type="text"
        />
        <Row>
          <Column>
            <StatesDropdown
              defaultValue={user?.state}
              label="Estado"
              name="state"
              onChange={handleChange}
            />
          </Column>
          <Column>
            <Field
              defaultValue={user?.city}
              label="Cidade"
              name="city"
              onChange={handleChange}
              type="text"
            />
          </Column>
        </Row>
        <Field
          defaultValue={user?.email}
          label="Email*"
          name="email"
          onChange={handleChange}
          type="email"
        />
        <Row>
          <Column>
            <Field
              defaultValue={user?.password}
              label="Senha*"
              name="password"
              onChange={handleChange}
              type="password"
            />
          </Column>
          <Column>
            <Field
              defaultValue={user?.confirmPassword}
              label="Confirmar Senha*"
              name="confirmPassword"
              onChange={handleChange}
              type="password"
            />
          </Column>
        </Row>
        <Row>
          <CheckboxColumn>
            <Label>
              <Checkbox
                checked={agreeToAdhesionTerms}
                onChange={handleAdhesionTermsAgreementChange}
              />
              <span>
                Declaro que estou ciente de acordo com os{' '}
                <Link href="#" onClick={handleAdhesionTermsLinkClick}>
                  Termos de Adesão
                </Link>
              </span>
            </Label>
          </CheckboxColumn>
        </Row>
        <Row>
          <Column>
            <RegisterButton size="large" disabled={!agreeToAdhesionTerms}>
              Próximo
            </RegisterButton>
          </Column>
        </Row>
        <Row>
          <LoginLinkColumn>
            <Link href="/login">Já é assinante? Clique Aqui</Link>
          </LoginLinkColumn>
        </Row>
      </Register>
      {showAdhesionTerm && (
        <Modal
          onClick={() => serShowAdhesionTerm(!showAdhesionTerm)}
          title="TERMO DE ADESÃO - ASSINANTES SEGONHA"
        >
          <p>
            Pela presente Assinatura do Clube de Vantagens, a <b>ASSINANTE</b>{' '}
            estará aderindo aos serviços da <b>SEGONHA</b> por assinatura anual,
            por meio do qual receberá indicações de profissionais, fornecedores,
            lojas, produtos e serviços, bem como, benefícios, descontos e
            conteúdos exclusivos que facilitarão a vida da gestante e da mãe.
          </p>
          <p>
            Diante disso, a <b>ASSINANTE</b> declara ciência que:
          </p>
          <p>
            <b>a.</b> A <b>SEGONHA</b> não se responsabiliza pela execução dos
            serviços ou pela entrega dos produtos pelos PARCEIROS, não podendo
            ser acionada administrativa ou judicialmente por quaisquer vícios ou
            falhas dos produtos adquiridos e/ou serviços utilizados pela{' '}
            <b>ASSINANTE</b> por intermédio de indicação da <b>SEGONHA</b>;
          </p>
          <p>
            <b>b.</b> Os serviços oferecidos pela plataforma consistem apenas em
            atividades de intermediação e busca para fins exclusivos de
            viabilizar a conexão entre <b>ASSINANTE</b> e profissionais/marcas{' '}
            <b>PARCEIRAS</b>;
          </p>
          <p>
            <b>c.</b> Os profissionais, fornecedores, lojas, produtos e serviços{' '}
            <b>PARCEIROS</b> da <b>SEGONHA</b> serão única e exclusivamente
            responsáveis pela qualidade, regularidade, garantia, integridade e
            legitimidade dos produtos e serviços que oferecem;
          </p>
          <p>
            <b>d.</b> A <b>SEGONHA</b> não se responsabiliza por eventual
            reajuste de preços dos serviços e produtos dos <b>PARCEIROS</b>,
            garantindo, contudo, os descontos oferecidos exclusivamente aos
            assinantes do Clube de Vantagens;
          </p>
          <p>
            <b>e.</b> A <b>SEGONHA</b> não se responsabiliza pelo uso indevido e
            negligente dos dados pessoais e sensíveis dos <b>ASSINANTES</b> por
            parte dos profissionais e fornecedores <b>PARCEIROS</b>;
          </p>
          <p>
            <b>f.</b> Salvo disposição contrária da marca ou profissional{' '}
            <b>PARCEIRO</b>, não será possível cumular o desconto oferecido aos
            assinantes do Clube de Vantagens com outros descontos oferecidos
            pelo <b>PARCEIRO</b>;
          </p>
          <p>
            <b>g.</b> Caso a <b>ASSINANTE</b> deseje realizar o cancelamento da
            assinatura após decorridos 07 dias da data da assinatura, serão
            retidos os valores proporcionais aos meses utilizados e uma multa no
            percentual de 30% sobre os meses não utilizados, a qual será cobrada
            em decorrência dos encargos da operadora do cartão de crédito;
          </p>
          <p>
            <b>h.</b> Não caberá devolução de valores de assinaturas do Clube de
            Vantagens em decorrência do encerramento de qualquer parceria com
            marca ou profissional <b>PARCEIRO</b>.
          </p>
        </Modal>
      )}
    </>
  )
}
