import React from 'react'
import styled from 'styled-components/macro'

import Container from './Container'
import theme from '../utils/theme'

const Video = styled.div`
  background: ${theme?.color?.secondary};
  text-align: center;
  iframe {
    border: 5px solid ${theme?.color?.details};
    border-radius: 0.5rem;
    height: 45rem;
    max-width: 80rem;
    width: 100%;
  }
  @media (max-width: 400px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media (min-width: 401px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
`

export default () => (
  <Video>
    <Container>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        frameBorder="0"
        src="https://www.youtube-nocookie.com/embed/1o5C4raNkHs"
        title="Clube Segonha"
      ></iframe>
    </Container>
  </Video>
)
