import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  className?: string
  children: JSX.Element | JSX.Element[] | string | string[]
  onClick?: any
  size?: string
  disabled?: boolean
}

const Button = styled.button`
  background: ${theme?.color?.initial};
  border-color: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  color: ${theme?.color?.primary};
  line-height: 3.4rem;
  text-transform: none;
  transition: all 0.3s;
  &:hover {
    background: ${theme?.color?.quaternary};
    border-color: ${theme?.color?.quaternary};
  }
`
const ButtonLarge = styled(Button)`
  height: 4.8rem;
  line-height: 4.6rem;
  padding: 0 5rem;
`
const ButtonSmall = styled(Button)`
  height: 2.4rem;
  line-height: 2.2rem;
  padding: 0 1.5rem;
`

export default ({
  className = '',
  children,
  onClick,
  size = '',
  disabled = false,
}: PropType) =>
  size === 'large' ? (
    <ButtonLarge className={className} onClick={onClick} disabled={disabled}>
      {children}
    </ButtonLarge>
  ) : size === 'small' ? (
    <ButtonSmall className={className} onClick={onClick} disabled={disabled}>
      {children}
    </ButtonSmall>
  ) : (
    <Button className={className} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  )
