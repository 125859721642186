import React from 'react'
import Loader from './Loader'
import styled from 'styled-components/macro'
import theme from '../utils/theme'

type PropType = {
  loading: boolean
  children: any
  loadingText?: string
  center?: boolean
}

const Container = styled.div`
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
`

const LoadingText = styled.p`
  color: ${theme.color.primary};
  font-size: 1.5rem;
  font-weight: 600;
  margin: 2rem auto 5rem auto;
  text-align: center;
`

export default ({
  loading,
  children,
  loadingText,
  center = true,
}: PropType) => {
  const Wrapper = center ? Container : React.Fragment

  if (loading) {
    return (
      <Wrapper>
        <Loader loading={loading} />
        {loadingText && <LoadingText>{loadingText}</LoadingText>}
      </Wrapper>
    )
  }

  return children
}
