import styled from 'styled-components/macro'

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  padding: 2rem 8rem;
  max-width: 110rem;
  gap: 2rem;
  @media (max-width: 430px) {
    justify-content: center;
    padding: 2rem;
    margin-bottom: 4rem;
  }
`
