import React, { useEffect, useState } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { createGlobalStyle } from 'styled-components/macro'

import home from '../pages/home'
import tracker from '../utils/tracker'
import theme from '../utils/theme'
import specialists from '../pages/specialists'
import partners from '../pages/partners'
import discounts from '../pages/discounts'
import login from '../pages/login'
import recover from '../pages/recover'
import register from '../pages/register'
import payment from '../pages/payment'
import LoadableContent from './LoadableContent'
import { getAuth, getUser } from '../utils/api'
import AuthType from '../types/auth'
import account from '../pages/account'
import discount from '../pages/discount'
import UserType, { hasValidSubscription } from '../types/user'
import success from '../pages/success'

const GlobalStyle = createGlobalStyle`
  @media print {
    header, footer {
      display: none;
    }
    body {
      background: #fff;
    }
  }

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  html, body {
    box-sizing: border-box;
    height: 100%;
    padding: 0;
    margin: 0;
  }

  body {
    background: ${theme?.color?.secondary};
    font-size: 15px;
    font-weight: ${theme?.font?.regular};
  }

  #root {
    box-sizing: border-box;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  header, footer {
    flex-grow: 0;
    flex-shrink: 0;
  }

  main {
    flex-grow: 1;
  }

  .swal-modal {
    max-width: 500px;
    .swal-title {
      color: ${theme?.color?.primary};
    }
    .swal-footer {
      text-align: center;
    }
    .swal-button {
      background: ${theme?.color?.primary};
      border-radius: 20px;
      line-height: 1;
      font-size: 15px;
      font-weight: 600;
      text-transform: capitalize;
      &:not([disabled]):hover {
        background: ${theme?.color?.primary};
        opacity: .75;
      }
      &.swal-button--cancel {
        background: ${theme?.color?.quaternary};
        &:not([disabled]):hover {
          background: ${theme?.color?.quaternary};
          opacity: .75;
        }
      }
    }
  }
`

const PaymentRoute = ({ component: Component, ...rest }: any) => {
  tracker(rest?.location?.pathname)
  const [user, setUser] = useState<UserType>()
  const [auth, setAuth] = useState<AuthType>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAuth((data: any) => {
      setAuth(data)

      if (data == null) {
        setIsLoading(false)
        return
      }

      getUser(data?.uid).then((user: UserType) => {
        setUser(user as any)
        setIsLoading(false)
      })
    })
  }, [])

  return (
    <LoadableContent loading={isLoading}>
      <Route
        {...rest}
        render={props =>
          auth != null && auth?.uid ? (
            user?.uid && hasValidSubscription(user) ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/my-account',
                  state: { from: props.location },
                }}
              />
            )
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      />
    </LoadableContent>
  )
}

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  tracker(rest?.location?.pathname)
  const [auth, setAuth] = useState<AuthType>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    getAuth((data: any) => {
      setAuth(data)
      setIsLoading(false)
    })
  }, [])

  return (
    <LoadableContent loading={isLoading}>
      <Route
        {...rest}
        render={props =>
          auth?.uid ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }
      />
    </LoadableContent>
  )
}

const PublicRoute = ({ component: Component, ...rest }: any) => {
  tracker(rest?.location?.pathname)

  return <Route {...rest} render={props => <Component {...props} />} />
}

const RedirectRoute = ({ component: Component, ...rest }: any) => {
  tracker(rest?.location?.pathname)

  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect to={{ pathname: '/', state: { from: location } }} />
      )}
    />
  )
}

export default () => (
  <BrowserRouter>
    <GlobalStyle />
    <Switch>
      <PublicRoute component={login} exact path="/login" />
      <PublicRoute component={recover} exact path="/recuperar-senha" />
      <PublicRoute component={register} exact path="/registrar" />
      <PublicRoute component={payment} exact path="/pagamento" />
      <PublicRoute component={success} exact path="/success" />
      <PublicRoute component={home} exact path="/" />
      <PublicRoute component={specialists} exact path="/specialists" />
      <PublicRoute component={partners} exact path="/partners" />
      <PaymentRoute component={discount} exact path="/discounts/:id" />
      <PublicRoute component={discounts} exact path="/discounts" />
      <PrivateRoute component={account} exact path="/my-account" />
      <PublicRoute component={RedirectRoute} />
    </Switch>
  </BrowserRouter>
)
