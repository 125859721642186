import React from 'react'
import theme from '../utils/theme'

import { BounceLoader } from 'react-spinners'

type PropType = {
  loading: boolean
}

const cssOverride: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  margin: '2rem auto',
}

export default ({ loading }: PropType) => {
  if (!loading) {
    return null
  }

  return <BounceLoader color={theme?.color.primary} cssOverride={cssOverride} />
}
