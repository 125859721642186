import styled from 'styled-components/macro'
import theme from './utils/theme'

export const Main = styled.main`
  padding-top: 8rem;
  text-align: center;
`

export const Container = styled.div`
  align-items: center;
  margin: 0 auto;
  max-width: 110rem;
  padding: 2rem 8rem;
  position: relative;
  @media (max-width: 430px) {
    padding: 2rem;
  }
`

export const Description = styled.p`
  font-size: 2.1rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  position: relative;
  @media (max-width: 430px) {
    font-size: 14px;
    margin-bottom: 0.3rem;
    line-height: 1;
  }
`
export const PartnerName = styled.h4`
  color: ${theme.color.primary};
  font-weight: ${theme?.font?.bold};
  font-size: 16px;
  margin-top: 20px;
  width: 100%;
  @media (max-width: 430px) {
    font-size: 14px;
    margin-top: 12px;
  }
`

export const PartnerDescription = styled.span`
  color: ${theme.color.details};
  font-size: 14px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 430px) {
    font-size: 12px;
  }
`

export const DiscountDescription = styled.span`
  color: ${theme.color.details};
  font-weight: ${theme?.font?.bold};
  font-size: 14px;
  width: 100%;
  margin-top: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 430px) {
    font-size: 12px;
    margin-top: 6px;
  }
`

export const PartnerImage = styled.img`
  background-size: contain;
  max-height: 100px;
`
