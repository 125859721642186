import React from 'react'
import styled from 'styled-components/macro'
import theme from '../utils/theme'
import Container from '../components/Container'
import Menu from './Menu'
import CategoryType from '../types/category'
import Row from './Row'

type PropType = {
  categories: CategoryType[]
  placeholder?: string
  onChange: React.ChangeEventHandler
  onFilter: Function
}

const SearchContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem 8rem;
  position: relative;
  @media (max-width: 430px) {
    padding: 0.4rem 1.6rem;
  }
`

const Input = styled.input`
  background-color: ${theme.color.initial} !important;
  padding: 2.5rem 4rem !important;
  border-radius: 10px !important;
  font-size: 16px;
  &:focus {
    border: 2px solid;
    border-color: ${theme.color.tertiary} !important;
  }
  @media (max-width: 430px) {
    font-size: 14px;
    padding: 0.5rem 0.8rem !important;
  }
`

const ColumnAuto = styled.div`
  width: auto;
`

const ColumnMatch = styled.div`
  width: 100%;
`

export default ({ categories, placeholder, onChange, onFilter }: PropType) => {
  return (
    <SearchContainer>
      <Row>
        {categories != undefined && categories.length > 1 && (
          <ColumnAuto>
            <Menu onFilter={onFilter} categories={categories} />
          </ColumnAuto>
        )}
        <ColumnMatch>
          <Input type="text" onChange={onChange} placeholder={placeholder} />
        </ColumnMatch>
      </Row>
    </SearchContainer>
  )
}
