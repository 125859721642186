import {
  Paper as PaperMUI,
  Table as TableMUI,
  TableBody as TableBodyMUI,
  TableCell as TableCellMUI,
  TableHead as TableHeadMUI,
  TableRow as TableRowMUI,
} from '@material-ui/core'
import styled from 'styled-components/macro'
import theme from '../utils/theme'

export const TablePaper = styled(PaperMUI)`
  && {
    box-shadow: none;
    border-collapse: inherit;
  }
`

export const Table = styled(TableMUI)`
  && {
    outline: 1px solid ${theme?.color?.tertiary};
    border-radius: 4px;

    @media (max-width: 40rem) {
      display: block;
    }
  }
`

export const TableBody = styled(TableBodyMUI)`
  && {
  }
`

export const TableHeader = styled(TableCellMUI)`
  && {
    color: ${theme?.color?.primary};
    font-size: ${theme?.font?.normal};
    font-weight: ${theme?.font?.bold};
    padding: 0.5rem;
    border-bottom: 1px solid ${theme?.color?.tertiary};
  }
`

export const TableCell = styled(TableCellMUI)`
  && {
    color: ${theme?.color?.tertiary};
    font-size: ${theme?.font?.normal};
    font-weight: ${theme?.font?.regular};
  }
`

export const TableHead = styled(TableHeadMUI)`
  && {
    background: ${theme?.color?.secondary};
  }
`

export const TableRow = styled(TableRowMUI)`
  && {
    height: 48px;
    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }
`
