import 'firebase/auth'
import 'firebase/database'
import { auth, database, initializeApp, storage } from 'firebase/app'

import PartnerType from '../types/partner'
import DiscountType from '../types/discount'
import DiscountDataType from '../types/discountData'
import UserType from '../types/user'
import LoginType from '../types/login'
import warning from '../utils/warning'
import dialog from './dialog'
import RegisterUserType from '../types/registerUser'
import RegisterSubscriptionType from '../types/registerSubscription'
import cpfUtils from '../utils/cpf'
import dateUtils from '../utils/date'
import phoneUtil from '../utils/phone'
import SubscriptionType from '../types/subscription'
import CategoryType from '../types/category'

const snapshotToArray = (snapshot: any) => {
  let returnArr: any[] = []

  snapshot?.forEach((childSnapshot: any) => {
    let item = childSnapshot.val()
    item.key = childSnapshot.key
    returnArr.push(item)
  })

  return returnArr
}

export const initialize = () => {
  initializeApp({
    apiKey: process?.env?.REACT_APP_FIREBASE_KEY,
    authDomain: process?.env?.REACT_APP_FIREBASE_DOMAIN,
    databaseURL: process?.env?.REACT_APP_FIREBASE_DATABASE,
    projectId: process?.env?.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process?.env?.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process?.env?.REACT_APP_FIREBASE_SENDER_ID,
  })
}

const get = async (reference: string) => {
  try {
    const snapshot: any = await database()
      ?.ref(reference)
      ?.once('value')
    return snapshot?.val() || {}
  } catch (error) {
    warning(error)
  }
}

const list = async (reference: string) => {
  try {
    const snapshot: any = await database()
      ?.ref(reference)
      ?.once('value')
    return snapshotToArray(snapshot)
  } catch (error) {
    warning(error)
  }
}

export const post = async (reference: string, value: any) => {
  try {
    const date = dateUtils?.getNewDate()
    await database()
      ?.ref(reference)
      ?.set({
        ...value,
        createdAt: date,
        updatedAt: date,
      })
    return value
  } catch (error) {
    warning(error)
  }
}

export const update = async (reference: string, value: any) => {
  try {
    const updatedAt = dateUtils?.getNewDate()
    const oldValue = await get(reference)
    if (!value?.createdAt) value.createdAt = updatedAt
    const newValue = { ...oldValue, ...value, updatedAt }
    await database()
      ?.ref(reference)
      ?.set(newValue)
    return newValue
  } catch (error) {
    warning(error)
  }
}

export const getStorageUrl = async (reference: string | any) => {
  try {
    const snapshot: any = await storage()
      ?.ref(reference)
      ?.getDownloadURL()
    return snapshot?.val() || {}
  } catch (error) {
    warning(error)
  }
}

const getPartners = async () => {
  try {
    const snapshot: any = await database()
      ?.ref(`/Partners`)
      ?.once('value')
    return snapshotToArray(snapshot)
  } catch (error) {
    warning(error)
  }
}

const getPartnersByTypeAndQuery = async (
  type: string = '',
  search: string = '',
) => {
  try {
    const snapshot: any = await database()
      ?.ref(`/Partners`)
      ?.orderByChild('type')
      ?.equalTo(type)
      ?.once('value')

    const partners = snapshotToArray(snapshot)

    if (search) {
      return partners?.filter((partner: PartnerType) => {
        const name = partner?.name?.toLowerCase()
        const searchLowerCase = search?.toLowerCase()
        return name?.includes(searchLowerCase)
      })
    }

    return partners
  } catch (error) {
    warning(error)
  }
}

export const getSpecialists = async (
  query: string = '',
): Promise<PartnerType[] | any> => {
  try {
    return await getPartnersByTypeAndQuery('Specialist', query)
  } catch (error) {
    warning(error)
  }
}

export const getBrands = async (
  query: string = '',
): Promise<PartnerType[] | any> => {
  try {
    return await getPartnersByTypeAndQuery('Brand', query)
  } catch (error) {
    warning(error)
  }
}

export const getDiscounts = async (): Promise<DiscountType | any> => {
  try {
    const partners = await getPartners()
    const discounts = await list(`/Discounts`)

    const aggregatedDiscounts = discounts
      ?.filter((data: DiscountDataType) => {
        var expDate
        if (!data?.expirationDate || typeof data?.expirationDate !== 'string') {
          expDate = Date.now().valueOf()
        } else {
          expDate = +data?.expirationDate
        }
        return Date.now().valueOf() <= expDate
      })
      ?.map((data: DiscountDataType) => {
        const partner = partners?.find(
          (partner: PartnerType) => data?.partnerId == partner?.uid,
        )
        return {
          partner: partner,
          description: data?.description,
          expirationDate: data?.expirationDate,
          uid: data?.uid,
          imageUrl: data?.imageUrl,
        }
      })
    return aggregatedDiscounts
  } catch (error) {
    warning(error)
  }
}

export const getPartner = async (
  uid: string = '',
): Promise<PartnerType | any> => {
  try {
    return await get(`/Partners/${uid}`)
  } catch (error) {
    warning(error)
  }
}

export const getDiscount = async (
  uid: string = '',
): Promise<DiscountType | any> => {
  try {
    const discount = await get(`/Discounts/${uid}`)
    const partner = await getPartner(discount.partnerId)
    return {
      partner: partner,
      description: discount.description,
      expirationDate: discount.expirationDate,
      uid: discount.uid,
      code: discount.code,
    }
  } catch (error) {
    warning(error)
  }
}

export const getUser = async (userId: string = ''): Promise<UserType | any> => {
  try {
    return await get(`/Users/${userId}`)
  } catch (error) {
    warning(error)
  }
}

export const updateUser = async (
  userId: string = '',
  userData: UserType,
): Promise<UserType | any> => {
  try {
    return await update(`/Users/${userId}`, userData)
  } catch (error) {
    warning(error)
  }
}

export const login = async ({
  email = '',
  password = '',
}: LoginType): Promise<string | any> => {
  try {
    const response = await auth()?.signInWithEmailAndPassword(email, password)
    const user = await getUser(response?.user?.uid)
    if (!user?.cpf) return Promise?.reject(null)
    return user
  } catch (error) {
    warning(error)
  }
}

export const logout = async () => {
  try {
    await auth()?.signOut()
  } catch (error) {
    warning(error)
  }
}

export const recover = async (email: string) => {
  try {
    await auth()?.sendPasswordResetEmail(email)
    dialog?.alert(
      'Por favor, verifique seu email! Enviamos um link para você recuperar sua senha. ',
    )
  } catch (error) {
    warning(error)
  }
}

export const getAuth = (callback: any) => {
  try {
    return auth()?.onAuthStateChanged(callback)
  } catch (error) {
    warning(error)
  }
}

export const register = async ({
  firstName = '',
  lastName = '',
  cpf = '',
  email = '',
  phone = '',
  streetAddress = '',
  city = '',
  state = '',
  country = 'Brasil',
  password = '',
  confirmPassword = '',
}: RegisterUserType): Promise<UserType | any> => {
  if (!firstName || !lastName || !cpf || !email || !phone || !password) {
    warning({ code: 'register/missing-field' })
    return Promise?.reject(null)
  }
  if (!cpfUtils.validation(cpf)) {
    warning({ code: 'auth/invalid-cpf' })
    return Promise?.reject(null)
  }
  if (!phoneUtil.validation(phone)) {
    warning({ code: 'auth/invalid-phone' })
    return Promise?.reject(null)
  }
  if (password !== confirmPassword) {
    warning({ code: 'register/invalid-confirm-password' })
    return Promise?.reject(null)
  }

  try {
    const response = await auth()?.createUserWithEmailAndPassword(
      email,
      password,
    )
    const date = dateUtils?.getNewDate()
    return await post(`/Users/${response?.user?.uid || cpf}`, {
      firstName,
      lastName,
      cpf,
      email,
      phone,
      streetAddress,
      city,
      state,
      country,
      uid: response?.user?.uid,
      createdAt: date,
      updatedAt: date,
    })
  } catch (error) {
    warning(error)
  }
}

export const registerSubscription = async (
  subscription: RegisterSubscriptionType,
): Promise<SubscriptionType | any> => {
  if (!subscription?.userId) {
    warning({ code: 'notes/invalid-user' })
    return Promise?.reject(null)
  }

  try {
    const uid = String(Date.now())
    return await post(`/Users/${subscription?.userId}/subscriptions/${uid}`, {
      ...subscription,
      uid: uid,
    })
  } catch (error) {
    warning(error)
  }
}

export const getCategoriesByType = async (
  type: string = '',
): Promise<CategoryType[] | any> => {
  try {
    const snapshot: any = await database()
      ?.ref(`/Categories`)
      ?.orderByChild('type')
      ?.equalTo(type)
      ?.once('value')

    return snapshotToArray(snapshot)
  } catch (error) {
    warning(error)
  }
}

export const getCategories = async (): Promise<CategoryType[] | any> => {
  try {
    const snapshot: any = await database()
      ?.ref(`/Categories`)
      ?.once('value')
    return snapshotToArray(snapshot)
  } catch (error) {
    warning(error)
  }
}

export default {
  getSpecialists,
  getBrands,
  getDiscounts,
  getDiscount,
  getStorageUrl,
  initialize,
  getUser,
  login,
  logout,
  recover,
  getAuth,
  register,
  registerSubscription,
  getCategoriesByType,
  getCategories,
}
