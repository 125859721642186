import Payment from 'payment'
import {
  PagSeguroChargeType,
  PagSeguroOrderType,
  PagSeguroType,
} from '../types/pagseguro'
import UserType from '../types/user'
import { pagSeguroWarning } from './warning'

type OrderPropType = {
  pagSeguroClient: PagSeguroType
  holder: string
  number: string
  expiration: string
  cvv: string
  customer: UserType
  callback?: (user: UserType, response: PagSeguroOrderType | null) => void
}

type ChargePropType = {
  pagSeguroClient: PagSeguroType
  holder: string
  number: string
  expiration: string
  cvv: string
  callback?: (response: PagSeguroChargeType | null) => void
}

const getOrderOptions = (encryptedCard: string, customer: UserType) => {
  return {
    method: 'POST',
    headers: {
      Authorization: process.env.REACT_APP_PAGSEGURO_KEY as string,
      'Content-Type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify({
      reference_id:
        customer?.cpf.replaceAll('.', '').replaceAll('-', '') +
        '_' +
        Date.now().toString(),
      customer: {
        name: customer?.firstName + ' ' + customer?.lastName,
        email: customer?.email,
        tax_id: customer?.cpf.replaceAll('.', '').replaceAll('-', ''),
      },
      items: [
        {
          name: 'Assinatura Anual - Clube de Vantagens Segonha',
          quantity: 1,
          unit_amount: 25188,
        },
      ],
      charges: [
        {
          description: 'Assinatura Anual - Clube de Vantagens Segonha',
          amount: {
            currency: 'BRL',
            value: 25188,
          },
          payment_method: {
            type: 'CREDIT_CARD',
            installments: 12,
            capture: true,
            soft_descriptor: 'Segonha ',
            card: {
              encrypted: encryptedCard,
            },
          },
        },
      ],
    }),
  }
}

const encryptCard = ({
  pagSeguroClient,
  holder,
  number,
  expiration,
  cvv,
}: ChargePropType) => {
  const monthYear = Payment.fns.cardExpiryVal(expiration)

  pagSeguroClient.PagSeguro.setUp({
    env: pagSeguroClient.PagSeguro.env.SANDBOX,
  })

  const publicKey = process.env.REACT_APP_PAGSEGURO_PUBLIC_KEY as string

  return pagSeguroClient.PagSeguro.encryptCard({
    publicKey: publicKey,
    holder: holder,
    number: number,
    expMonth: String(monthYear.month),
    expYear: String(monthYear.year),
    securityCode: cvv,
  })
}

export const charge = ({
  pagSeguroClient,
  holder,
  number,
  expiration,
  cvv,
  customer,
  callback,
}: OrderPropType) => {
  const encryptedCard = encryptCard({
    pagSeguroClient,
    holder,
    number,
    expiration,
    cvv,
  })

  if (encryptedCard.hasErrors) {
    pagSeguroWarning(encryptedCard.errors)
    if (callback) {
      callback(customer, null)
    }
    return
  }

  var options = getOrderOptions(encryptedCard.encryptedCard, customer)
  fetch(process.env.REACT_APP_PAGSEGURO_CHARGE_URL as string, options)
    .then(response => {
      return response.json()
    })
    .then((response: PagSeguroOrderType) => {
      if (callback) {
        callback(customer, response)
      }
    })
    .catch((err: Error) => {
      if (callback) {
        callback(customer, null)
      }
      pagSeguroWarning()
      console.error(err)
    })
}
