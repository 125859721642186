import React from 'react'
import Footer from '../components/Footer'

import Header from '../components/Header'
import Info from '../components/Info'

export default () => (
  <>
    <Header />
    <Info />
    <Footer />
  </>
)
