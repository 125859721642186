import { useEffect, useState } from 'react'
import SubscriptionType, { hasExpired } from '../types/subscription'

export const isDefined = (value: any): boolean => {
  return typeof value !== 'undefined' && value !== null
}

export default (
  subscriptions: any,
): [Array<SubscriptionType> | null, boolean] => {
  const [subscriptionsArray, setSubscriptionArray] = useState<Array<
    SubscriptionType
  > | null>(null)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    if (isDefined(subscriptions)) {
      const array = Object.values(subscriptions) as Array<SubscriptionType>
      const validSubscription = array.find(
        subscription => !hasExpired(subscription),
      )
      setSubscriptionArray(array)
      setIsValid(isDefined(validSubscription))
    }
  }, [subscriptions])

  return [subscriptionsArray, isValid]
}
