import { useEffect, useState } from 'react'
import UserType from '../types/user'
import useAuth from '../hooks/auth'
import { getUser, updateUser } from '../utils/api'
import warning from '../utils/warning'
import cpfUtils from '../utils/cpf'
import phoneUtil from '../utils/phone'

export default (): [
  UserType,
  (event: any) => void,
  () => void,
  boolean,
  boolean,
] => {
  const [user, setUser] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [isSaving, setIsSaving] = useState(false)
  const [auth] = useAuth()

  useEffect(() => {
    const fetchUser = async () => {
      if (!auth) {
        return
      }
      getUser(auth?.uid).then((user: UserType) => {
        setUser(user as any)
        setIsLoading(false)
      })
    }
    fetchUser()
  }, [auth])

  const handleChange = (event: any): void => {
    event?.preventDefault()
    event?.stopPropagation()
    let { name, value } = event?.target
    setUser({ ...user, [String(name)]: value })
  }

  const handleSubmit = () => {
    const userToValidate = user as UserType

    if (
      !userToValidate?.firstName ||
      !userToValidate?.lastName ||
      !userToValidate?.cpf ||
      !userToValidate?.email ||
      !userToValidate?.phone
    ) {
      warning({ code: 'register/missing-field' })
      return
    }
    if (!cpfUtils.validation(userToValidate?.cpf)) {
      warning({ code: 'auth/invalid-cpf' })
      return
    }
    if (!phoneUtil.validation(userToValidate?.phone)) {
      warning({ code: 'auth/invalid-phone' })
      return
    }

    setIsSaving(true)

    updateUser(auth?.uid, userToValidate).then(() => {
      setIsSaving(false)
    })
  }

  return [user as UserType, handleChange, handleSubmit, isLoading, isSaving]
}
