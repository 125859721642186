import React from 'react'
import styled from 'styled-components/macro'

import theme from '../utils/theme'

type PropType = {
  children: JSX.Element | JSX.Element[] | string | string[]
  className?: string
  href?: string
  onClick?: any
}

const Link = styled.a`
  color: ${theme?.color?.details};
  transition: opacity 0.3s;
`

export default ({ children, className = '', href = '', onClick }: PropType) => (
  <Link className={className} href={href} onClick={onClick}>
    {children}
  </Link>
)
