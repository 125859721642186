import React from 'react'
import styled from 'styled-components/macro'

import Link from '../components/Link'
import { getIcon } from '../components/IconLogo'

type PropType = {
  href?: string
}

const Icon = styled.svg`
  fill: ${({ color }) => color || '#888'};
  height: 15rem;
  transition: opacity 0.3s;
`

const Logo = styled(Link)`
  display: inline-block;
  height: 62.5px;
  overflow: hidden;
  position: relative;
  width: 175px;
  svg {
    height: 175px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export default ({ href = '/' }: PropType) => (
  <Logo href={href}>
    <Icon viewBox="0 0 841.89 595.28">{getIcon('logo')}</Icon>
  </Logo>
)
