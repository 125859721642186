import { EpharmaResult, EpharmaTokenType } from '../types/epharma'
import RegisterSubscriptionType from '../types/registerSubscription'
import UserType from '../types/user'

const EPHARMA_URL_TOKEN =
  (process.env.REACT_APP_EPHARMA_BASE_URL as string) + '/oauth/token'

const EPHARMA_URL_BENEFICIARY =
  (process.env.REACT_APP_EPHARMA_BASE_URL as string) +
  '/api/ManutencaoBeneficiario/BeneficiariosCartaoCliente'

export const getTokenRequest = () => {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'password',
      client_id: process.env.REACT_APP_EPHARMA_CLIENT_ID as string,
      username: process.env.REACT_APP_EPHARMA_USERNAME as string,
      password: process.env.REACT_APP_EPHARMA_PASSWORD as string,
    }),
  }
}

export const addBeneficiaryRequest = (
  user: UserType,
  subscription: RegisterSubscriptionType,
  token: string,
) => {
  var startDate = new Date()
  startDate.setDate(startDate.getDate() + 1)
  var startDateStr = subscription?.startDate
  if (subscription?.startDate != undefined) {
    startDate = new Date(subscription?.startDate)
    startDate.setDate(startDate.getDate() + 1)
    var mm = startDate.getMonth() + 1 // getMonth() is zero-based
    var dd = startDate.getDate()

    startDateStr = [
      (dd > 9 ? '' : '0') + dd,
      '/',
      (mm > 9 ? '' : '0') + mm,
      '/',
      startDate.getFullYear(),
    ].join('')
  }
  var endDate = new Date()
  var endDateStr = subscription?.endDate
  if (subscription?.endDate != undefined) {
    endDate = new Date(subscription?.endDate)
    var mm = endDate.getMonth() + 1 // getMonth() is zero-based
    var dd = endDate.getDate()

    endDateStr = [
      (dd > 9 ? '' : '0') + dd,
      '/',
      (mm > 9 ? '' : '0') + mm,
      '/',
      endDate.getFullYear(),
    ].join('')
  }

  var cpf = user?.cpf.replaceAll('.', '').replaceAll('-', '')

  return {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      beneficiario: [
        {
          cartaoTitular: cpf,
          cartaoUsuario: cpf,
          planoCodigo: process.env.REACT_APP_EPHARMA_CODIGO_PLANO,
          inicioVigencia: startDateStr,
          fimVigencia: endDateStr,
          matricula: cpf,
          grauDependencia: '00',
          tipoBeneficiario: 'T', //Enviar T (titular) ou D (dependente)
          dadosBeneficiario: {
            nomeBeneficiario: user?.firstName + ' ' + user?.lastName,
            cpf: cpf,
            dataNascimento: '01/01/1990',
            sexo: 'M', // Enviar: F ou M ou X
          },
          endereco: {
            cep: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            uf: '',
          },
          telefones: {
            celular: '',
            residencial: '',
            comercial: '',
          },
        },
      ],
    }),
  }
}

export const saveBeneficiary = async (
  user: UserType,
  subscription: RegisterSubscriptionType,
): Promise<boolean> => {
  try {
    var response = await fetch(EPHARMA_URL_TOKEN, getTokenRequest())
    var token: EpharmaTokenType = await response.json()
    var response2 = await fetch(
      EPHARMA_URL_BENEFICIARY,
      addBeneficiaryRequest(user, subscription, token.access_token),
    )
    var result: EpharmaResult = await response2.json()
    return (
      result != undefined &&
      result.data != undefined &&
      result.data.length >= 1 &&
      result.data[0].status == 'Exito'
    )
  } catch (error) {
    return false
  }
}
