import 'normalize.css'
import 'milligram'
import React from 'react'
import ReactDOM from 'react-dom'

import Router from './components/Router'
import api from './utils/api'

api.initialize()

ReactDOM.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>,
  document.getElementById('root'),
)
