import React from 'react'
import styled from 'styled-components/macro'

type PropType = {
  children?: any
  className?: string
}

const Row = styled.div``

export default ({ children = '', className = '' }: PropType) => (
  <Row className={`row ${className}`.trim()}>{children}</Row>
)
