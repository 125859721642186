import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../utils/theme'
import Button from '../Button'
import Column from '../Column'
import Field from '../Field'
import LoadableContent from '../LoadableContent'
import StatesDropdown from '../Register/StatesDropdown'
import Row from '../Row'

type PropType = {
  user: any
  handleChange: any
  handleSubmit: any
  isSaving: boolean
}

const UserData = styled.form`
  background: ${theme?.color?.initial};
  border-radius: ${theme?.main?.radius};
  display: block;
  margin-top: 2rem;
  padding: calc(${theme?.main?.spacing} / 2) ${theme?.main?.spacing}
    calc(${theme?.main?.spacing} / 5);
  text-align: center;
  width: 100%;
`

const UserDataTitle = styled.h2`
  color: ${theme?.color?.primary};
  font-weight: ${theme?.font?.bold};
  line-height: 2;
  margin: 0;
  padding: 0 1rem;
`

const SaveUserDataButtonColumn = styled(Column)`
  flex: 0 !important;
`

const SaveUserDataButton = styled(Button)`
  background: ${theme?.color?.primary};
  color: white;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
`

export default ({ user, handleChange, handleSubmit, isSaving }: PropType) => {
  return (
    <LoadableContent
      loading={isSaving}
      loadingText="Salvando dados do usuário"
      center={false}
    >
      <UserData onSubmit={handleSubmit}>
        <Row>
          <UserDataTitle>Meus dados</UserDataTitle>
        </Row>
        <Row>
          <Column>
            <Field
              defaultValue={user?.firstName}
              label="Primeiro nome*"
              name="firstName"
              onChange={handleChange}
              type="text"
            />
          </Column>
          <Column>
            <Field
              defaultValue={user?.lastName}
              label="Sobrenome*"
              name="lastName"
              onChange={handleChange}
              type="text"
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Field
              defaultValue={user?.cpf}
              label="CPF*"
              name="cpf"
              onChange={handleChange}
              type="cpf"
            />
          </Column>
          <Column>
            <Field
              defaultValue={user?.phone}
              label="Telefone*"
              name="phone"
              onChange={handleChange}
              type="phone"
            />
          </Column>
        </Row>
        <Field
          defaultValue={user?.streetAddress}
          label="Endereço"
          name="streetAddress"
          onChange={handleChange}
          type="text"
        />
        <Row>
          <Column>
            <StatesDropdown
              defaultValue={user?.state}
              label="Estado"
              name="state"
              onChange={handleChange}
            />
          </Column>
          <Column>
            <Field
              defaultValue={user?.city}
              label="Cidade"
              name="city"
              onChange={handleChange}
              type="text"
            />
          </Column>
        </Row>
        {/* <Field
          defaultValue={user?.email}
          label="Email*"
          name="email"
          onChange={handleChange}
          type="email"
        /> */}
        <Row>
          <SaveUserDataButtonColumn>
            <SaveUserDataButton size="large">Salvar</SaveUserDataButton>
          </SaveUserDataButtonColumn>
        </Row>
      </UserData>
    </LoadableContent>
  )
}
