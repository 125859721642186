import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CategoryType from '../types/category'
import theme from '../utils/theme'

const DropdownElement = styled.div`
z-index: 999;
position: absolute;
top: 6rem;
left: -1px;
border: 2px solid ${theme.color.primary};
border-radius: 15px !important;
background: ${theme.color.initial};
padding: 0;
color: ${theme.color.details}
text-align: left;
border-radius: 0.25rem;
p {
    margin: 0;
    padding: 2.5rem 1rem !important;
    border-bottom: 1px solid ${theme.color.tertiary};
    border-radius: 0px !important;
    min-width: 140px;
    &:hover {
        color: ${theme.color.initial};
        background-color: ${theme.color.primary};
        border-radius: 10px !important;
        border-radius: 0px !important;
      }
      &:first-child {
        border-radius: 10px 10px 0 0 !important;
      }
      &:last-child {
        border-bottom: 0 none;
        border-radius: 0 0 10px 10px !important;
      }
  }
  @media (max-width: 430px) {
    top: 4rem;
    p {
        min-width: 50px;
        padding: 1.6rem 0.3rem !important;
    }
  }
`

type DropdownProps = {
  categories: CategoryType[]
  showDropdown: boolean
  toggleDropdown: Function
  selection: Function
}

const Dropdown: React.FC<DropdownProps> = ({
  categories,
  selection,
}: DropdownProps): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false)

  const onClickHandler = (category: CategoryType): void => {
    selection(category)
  }

  useEffect(() => {
    setShowDropdown(showDropdown)
  }, [showDropdown])

  return (
    <>
      <DropdownElement
        className={showDropdown ? 'dropdown' : 'dropdown active'}
      >
        {categories.map(
          (category: CategoryType, index: number): JSX.Element => {
            return (
              <p
                key={index}
                onClick={(): void => {
                  onClickHandler(category)
                }}
              >
                {category?.name}
              </p>
            )
          },
        )}
      </DropdownElement>
    </>
  )
}

export default Dropdown
