import React from 'react'
import styled from 'styled-components/macro'
import theme from '../utils/theme'
import Button from './Button'
import Link from './Link'

const Club = styled.div.attrs({
  id: 'como-funciona',
})`
  background: ${theme?.color?.secondary};
  color: ${theme?.color?.primary};
  display: flex;
  justify-content: center;
  @media (max-width: 400px) {
    padding: 0 0.5rem 0 0.5rem;
  }
  @media (min-width: 401px) {
    padding: 5rem 1rem 5rem 0;
  }
`

const Title = styled.h1`
  color: ${theme?.color?.primary};
  font-weight: ${theme?.font?.bold};
  line-height: 1.3;
  margin: 0;
  @media (max-width: 400px) {
    font-size: 2rem;
    text-align: center;
    margin-top: 15px;
  }
  @media (min-width: 401px) {
    font-size: 4.2rem;
    text-align: center;
    margin-top: 0px;
  }
`

const Description = styled.p`
  font-size: 1.5rem;
  line-height: 1.3;
  position: relative;
  @media (max-width: 400px) {
    text-align: center;
    margin: 0.8rem 0;
  }
  @media (min-width: 401px) {
    text-align: center;
    margin: 3rem 0;
  }
`

const SignButton = styled(Button)`
  background: ${theme.color.details};
  border-color: ${theme.color.details};
  color: ${theme.color.initial};
  height: 4.8rem;
  padding: 0 5rem;
  margin-top: 15px;
  &:hover {
    background: ${theme.color.primary};
    border-color: ${theme.color.primary};
  }
  @media (max-width: 400px) {
    font-size: 1.3rem;
  }
  @media (min-width: 401px) {
    font-size: 1.5rem;
  }
`

const PriceTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 10px;
  padding-left: 0;
`

const DivBlock2 = styled.div`
  color: ${theme.color.details};
  align-items: flex-start;
  margin-left: 15px;
`

const DivBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    margin-bottom: 15px;
  }
  @media (min-width: 401px) {
    margin-bottom: 0;
  }
`

const DivBlock3 = styled.div`
  color: ${theme.color.primary};
  display: flex;
  width: 114px;
  margin-top: 5px;
  justify-content: space-between;
  align-items: flex-start;
`

const DivBlock4 = styled.div`
  display: flex;
  flex-direction: column;
`

const Heading2 = styled.h2`
  font-size: 50px;
  font-weight: 700;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: 0;
`

const Heading4 = styled.h4`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
`

const MoneyMark = styled.div`
  margin-top: -4px;
  margin-right: 5px;
`
const Container = styled.div`
  max-width: 70rem;
  position: relative;
  @media (max-width: 400px) {
    min-width: 20rem;
  }
  @media (min-width: 401px) {
    min-width: 40rem;
  }
`

export default () => (
  <Club>
    <Container>
      <div>
        <div>
          <Title children="Já pensou em ECONOMIZAR mais de R$ 1000,00 por mês por apenas R$ 0,69 ao dia? Isso é possível no ÚNICO clube de vantagens feito para a FAMÍLIA." />
          <Description children="Aqui temos descontos e benefícios em todas as lojas e  serviços que você pode precisar dia a dia da sua família. Cadastre-se e aproveite de forma ilimitada por apenas 20,99 ao mês " />
          <DivBlock>
            <Link href="/registrar">
              <SignButton>ASSINE JÁ!</SignButton>
            </Link>
            <PriceTag>
              <DivBlock2>
                no Plano anual de
                <DivBlock3>
                  <MoneyMark>
                    <div style={{ height: '20px' }}>12x</div>
                    <Heading4>R$</Heading4>
                  </MoneyMark>
                  <Heading2>20</Heading2>
                  <DivBlock4>
                    <Heading4>,99</Heading4>
                  </DivBlock4>
                </DivBlock3>
              </DivBlock2>
            </PriceTag>
          </DivBlock>
        </div>
      </div>
    </Container>
  </Club>
)
