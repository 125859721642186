import { useState, useEffect } from 'react'

export const useScript = (url: string, name: string) => {
  const [library, setLibrary] = useState({})

  useEffect(() => {
    const script = document.createElement('script')

    script.src = url
    script.async = true
    script.onload = () => setLibrary({ [name]: (window as any)[name] })

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url, name])

  return library
}
